
    <div class="outerbox_shadow">
        <div class="headerall one_grid mb-3 ">
            <div class="container-fluidd">
                <div class="">
                    <div class="outerbox_shadow">
                        <div class="btmcar card mb-0">
                            <div class="card-header row">
                                <div class="col-md-7">
                                <a class="card-title">
                                    <span class="headngins pldashboardmap">Installed Meter's Location</span>
                                </a>
                                </div>
                                <div class="col-md-5">
                                    <div class="authsearch">
                                        <div class="search">
                                        <input class="search-txt searchplaceholder" minlength="0" maxlength="7" type="text"
                                            placeholder="Search by Meter No" value="{{levelSearch}}"
                                            (input)="onLevelSearchChange($event.target.value)" autocomplete="off">
                                        <div class="search-btn nobg" href="#">
                                            <i class="fa fa-search"></i>
                                        </div>
                                        </div> 
                                    </div>
                                </div> 
                            </div>
                            <div class="card-bodyy pt-2">
                                <div class="innerboxx">
                                    <div class="row block2">
                                        <div class="col-md-12" *ngIf="coords.length==1">
                                            <map [coords]="updateCoordValue()"></map>
                                        </div>
                                        <div class="col-md-12" *ngIf="coords.length>1">
                                            <map [coords]="updateCoordValue()"></map>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
