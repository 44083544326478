<div class="sidebar-wrapper">
   <div class="logo">
      <a href="#" class="simple-text logo-mini">
         <div class="photo">
            <img src="../assets/img/avon.png" />
         </div>
      </a>
      <a href="#" class="simple-text logo-normal">
         {{currentUser.data.userData.name | titlecase}}
      </a>
   </div>
   <div class="user">
      <div class="photo">
         <img src="../assets/img/default-avatar.png" />
      </div>
      <div class="info">
         <a data-toggle="collapse" href="#profile" class="collapsed">
            <span>My Profile
               <b class="caret"> </b>
            </span>
         </a>
         <div class="collapse" id="profile">
            <ul class="nav">
               <li>
                  <a class="profile-dropdown" (click)="resetPassword()">
                     <span class="sidebar-normal"><i class="nc-icon nc-lock-circle-open subicondef"></i>Change
                        Password</span>
                  </a>
               </li>
               <li>
                  <a class="profile-dropdown" (click)="contactUs()">
                     <span class="sidebar-normal"><i class="nc-icon nc-badge subicondef"></i>Contact Us </span>
                  </a>
               </li>
               <li>
                  <a class="profile-dropdown" (click)="getProfileEdit()">
                     <span class="sidebar-normal"><i class="nc-icon nc-circle-09 subicondef"></i>Edit Profile</span>
                  </a>
               </li>
            </ul>
         </div>
      </div>
   </div>


   <ul class="nav" *ngIf="getThrough.authority">
      <li class="nav-item">
         <a class="nav-link" data-toggle="collapse" href="#dashboard">
            <i class="nc-icon nc-bank"></i>
            <p>
               Dashboard
               <b class="caret"></b>
            </p>
         </a>
         <div class="collapse" id="dashboard">
            <ul class="nav">
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_All_Griphical_Society_Deshboard == 1 ">
                  <a [routerLink]="['allsosGridDeshBoard']" class="nav-link">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>All Society
                        Dashboard</span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_All_Griphical_Deshboard == 1 ">
                  <a [routerLink]="['authBaseURl']" class="nav-link">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Graphical Dashboard</span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_All_Smart_Meter_Summery_Deshboard == 1 ">
                  <a [routerLink]="['/authView/smartMeterSummery']" class="nav-link">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Smart Meter Summery</span>
                  </a>
               </li>

               <li class="nav-item" *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_Deshboard == 1 ">
                  <a [routerLink]="['/gridDeshBoard']" class="nav-link">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Grid Dashboard</span>
                  </a>
               </li>

               <li class="nav-item" *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_Deshboard_Single == 1 ">
                  <a [routerLink]="['/gridDeshBoard1Ph']" class="nav-link">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Grid Dashboard 1-Ph</span>
                  </a>
               </li>
               <li class="nav-item" *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_Deshboard_Three == 1 ">
                  <a [routerLink]="['/gridDeshBoard3Ph']" class="nav-link">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Grid Dashboard 3-Ph</span>
                  </a>
               </li>
               <li class="nav-item" *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_Deshboard_Ltct == 1 ">
                  <a [routerLink]="['/gridDeshBoardltct']" class="nav-link">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Grid Dashboard Ltct</span>
                  </a>
               </li>



               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_All_Meter_Installed_Status == 1">
                  <a [routerLink]="['/MeterInstallDesh']" class="nav-link">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Progress Till Date</span>
                  </a>
               </li>

               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_All_Meter_Installed_Status_Pnb == 1">
                  <a [routerLink]="['/authView/ProgressTillDate']" class="nav-link">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Progress Till Date</span>
                  </a>
               </li>
               <li class="nav-item" *ngIf="getThrough.authority && getThrough.authority.Progress == 1">
                  <a [routerLink]="['/authView/progress']" class="nav-link">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Progress Till Date</span>
                  </a>
               </li>
               <li class="nav-item" *ngIf="getThrough.authority && getThrough.authority.Progress == 1">
                  <a [routerLink]="['/authView/dailyProgressSummery']" class="nav-link">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Daily Progress
                        Summery</span>
                  </a>
               </li>
               <!--<li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_All_Meter_Installed_Status_New == 1">
                  <a [routerLink]="['/MeterInstallDeshNew']" class="nav-link">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Progress Till Date
                        New</span>
                  </a>
               </li>-->
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_All_Meter_Installed_Status_New == 1">
                  <a [routerLink]="['/MeterInstallDeshNew']" class="nav-link">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Progress Summary</span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Replaced_Meters_Information_Report == 1">
                  <a [routerLink]="['/replacedMeterInfo']" class="nav-link">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Replaced Meter
                        Information</span>
                  </a>
               </li>
               <!--<li class="nav-item" *ngIf="getThrough.authority && getThrough.authority.Auth_Non_Communicated == 1 ">
                  <a [routerLink]="['/communicatedMeters']" class="nav-link">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Communicated</span>
                  </a>
               </li>-->
               <li class="nav-item" *ngIf="getThrough.authority && getThrough.authority.Auth_Non_Communicated == 1 ">
                  <a [routerLink]="['/nonCommunicatedMeters']" class="nav-link">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Non-Communicated</span>
                  </a>
               </li>
               <li class="nav-item" *ngIf="getThrough.authority && getThrough.authority.Auth_Non_Communicated_Govt == 1 ">
                  <a [routerLink]="['/nonCommunicatedMetersGovt']" class="nav-link">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Non-Communicated Govt</span>
                  </a>
               </li>
               <li class="nav-item" *ngIf="getThrough.authority && getThrough.authority.Auth_Non_Communicated_Pvt == 1 ">
                  <a [routerLink]="['/nonCommunicatedMetersPvt']" class="nav-link">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Non-Communicated Pvt</span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Old_New_Meter_Desh == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/oldNewMeterDeshAvon']" class="nav-link" title="Pending Commands">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Avon Meters Desh
                     </span>
                  </a>
               </li>

               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Old_New_Meter_Desh == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/solarMeterDeshAvon']" class="nav-link" title="Pending Commands">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Avon Solar Meters Desh
                     </span>
                  </a>
               </li>

               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Old_New_Meter_Desh_HPL == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/oldNewMeterDeshHpl']" class="nav-link" title="Pending Commands">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>HPL Meters Desh
                     </span>
                  </a>
               </li>

               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Old_New_Meter_Desh_HPL == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/solarMeterDeshHpl']" class="nav-link" title="Pending Commands">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>HPL Solar Meters Desh
                     </span>
                  </a>
               </li>

               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Old_New_Meter_Desh_Elide == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/oldNewMeterDeshAllied']" class="nav-link" title="Pending Commands">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Allied Meters Desh
                     </span>
                  </a>
               </li>

               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Old_New_Meter_Desh_Elide == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/solarMeterDeshAllied']" class="nav-link" title="Pending Commands">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Allied Solar Meters Desh
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Old_New_Meter_Desh_Elide == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/pspclPortalMeters']" class="nav-link" title="Pending Commands">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>PSPCL Portal Meters
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Old_New_Meter_Desh_Elide == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/pspclApiMeters']" class="nav-link" title="Pending Commands">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>PSPCL API Meters
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Integration_And_commissioning == 1 && enableSocietyChange">
                  <a [routerLink]="['/integrationCommissioning']" class="nav-link" title="Pending Commands">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Integration & Commissioning
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Integration_And_commissioning == 1 && enableSocietyChange">
                  <a [routerLink]="['/backgroundWork']" class="nav-link" title="Background Work">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Background Work
                     </span>
                  </a>
               </li>
               <li class="nav-item"
               *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Old_New_Meter_Desh == 1 && enableSocietyChange">
               <a [routerLink]="['/authView/amiHelpDesk']" class="nav-link" title="Pending Commands">
                  <span class="sidebar-normal">
                     <i class="nc-icon nc-explore-2 subicn"></i>AMI HelpDesk
                  </span>
               </a>
            </li>
            </ul>
         </div>
      </li>
      <li *ngIf="getThrough.authority && getThrough.authority.Auth_Menu == 1 " class="nav-item">
         <a class="nav-link" data-toggle="collapse" href="#authority">
            <i class="nc-icon nc-lock-circle-open"></i>
            <p>
               Authority's
               <b class="caret"></b>
            </p>
         </a>
         <div class="collapse" id="authority">
            <ul class="nav">
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_New_Scheduler == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/addSchedular']" class="nav-link" title="Add Schedular">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Add Scheduler
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_Authority == 1">
                  <a [routerLink]="['/authView/authorityview']" class="nav-link" title="Authority's">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>All Authorities</span>
                  </a>
               </li>

               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_Consumer_Notification == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/addconsumernotification']" class="nav-link"
                     title="Consumer's Notification">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Consumer's Notification
                     </span>
                  </a>
               </li>
               <li class="nav-item "
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Refund == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/refundAmount']" class="nav-link" title="consumerRefund">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Consumer Refund
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_DG == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/addDG']" class="nav-link" title="DG's">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>DG's
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_New_DCU_Command == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/adddcucommand']" class="nav-link" title="DCU's Command">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>DCU's Command
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_Group == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/addGroups']" class="nav-link" title="Group's">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Group's</span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_New_Level == 1">
                  <a [routerLink]="['/authView/authorityaddlevels']" class="nav-link" title="Level's">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Level's</span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_Meter_Firmware == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/meterFWVersion']" class="nav-link" title="Meter FW Version">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Meter FW Version</span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Admin_User_Remark_Revert == 1 && enableSocietyChange">
                  <a [routerLink]="['/userWork']" class="nav-link" title="User Work">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>User Work</span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_New_Meter_Command == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/addmetercommand']" class="nav-link" title="Meter's Command">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Meter's Command
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_Meter_Range == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/meterRange']" class="nav-link" title="Meter Range">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Meter Range</span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_New_Meter == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/newMeter']" class="nav-link" title="Pending Commands">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>New Meters Desh
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Old_New_Meter == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/oldNewMeter']" class="nav-link" title="Pending Commands">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Old New Meters Desh
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Old_New_Meter_Govt == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/oldNewMeterGovt']" class="nav-link" title="Pending Commands">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Old New Meters Govt
                     </span>
                  </a>
               </li>
               <li class="nav-item"
               *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Old_New_Meter_Pvt == 1 && enableSocietyChange">
               <a [routerLink]="['/authView/oldNewMeterPvt']" class="nav-link" title="Pending Commands">
                  <span class="sidebar-normal">
                     <i class="nc-icon nc-explore-2 subicn"></i>Old New Meters Pvt
                  </span>
               </a>
            </li>

               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Pending_Demands == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/pendingCommands']" class="nav-link" title="Pending Commands">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Pending Commands
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_Teriff == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/authorityaddteriff']" class="nav-link" title="Tariff's">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Tariff's
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_Tower == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/addTowers']" class="nav-link" title="Tower's">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Tower's
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_Tower == 1 && enableSocietyChange">
                  <a [routerLink]="['/authView/ebdgDeductions']" class="nav-link" title="EB/DG Deductions">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>EB/DG Deductions
                     </span>
                  </a>
               </li>
               <li class="nav-item" *ngIf="getThrough.authority && getThrough.authority.smart_meter_order_menu == 1">
                  <a [routerLink]="['/authView/smartMeterVender']" class="nav-link" title="Smart Meter Venders">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Smart Meter Vendors
                     </span>
                  </a>
               </li>
               <li class="nav-item" *ngIf="getThrough.authority && getThrough.authority.smart_meter_order_menu == 1">
                  <a [routerLink]="['/authView/receivedDispatched']" class="nav-link"
                     title="Received/Dispatched Meters">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Received/Dispatched Meters
                     </span>
                  </a>
               </li>

               <li class="nav-itemn subrepLI"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Reports == 1 && enableSocietyChange">
                  <a class="nav-link" data-toggle="collapse" href="#reports">
                     <i class="nc-icon nc-ruler-pencil"></i>
                     <p>
                        Reports
                        <b class="caret"></b>
                     </p>

                  </a>
                  <div class="collapse" id="reports">
                     <ul class="nav subrepUL"
                        *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Reports == 1">
                        <li class="nav-item"
                           *ngIf="getThrough.authority && (getThrough.authority.     Auth_Sub_Menu_Sub_All_Authority_Info_Report == 1 || getThrough.authority.     Auth_Sub_Menu_Sub_All_Authority_Wise_Info_Report == 1)">
                           <a [routerLink]="['/reports/authAllInfo']" title="All Authority Info Report"
                              class="nav-link">

                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Auth's Info
                              </span>
                           </a>
                        </li>

                        <li class="nav-item"
                           *ngIf="getThrough.authority && (getThrough.authority. Auth_Sub_Menu_Sub_All_Authority_Login_Logout_Report == 1 || getThrough.authority.Auth_Sub_Menu_Sub_All_Authority_Wise_Login_Logout_Report == 1)">
                           <a [routerLink]="['/reports/authAllLoginInfo']" title="Authority Login/Logout Report"
                              class="nav-link">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Auth's Login/Logout
                              </span>
                           </a>
                        </li>
                     </ul>
                  </div>
               </li>
            </ul>
         </div>
      </li>
      <li class="nav-item"
         *ngIf="getThrough.authority && getThrough.authority.contractor_Menu == 1  && enableSocietyChange">
         <a class="nav-link" data-toggle="collapse" href="#contractor">
            <i class="nc-icon nc-lock-circle-open"></i>
            <p>
               Contractor's
               <b class="caret"></b>
            </p>
         </a>
         <div class="collapse" id="contractor">
            <ul class="nav" id="Meter">
               <li class="nav-item" *ngIf="getThrough.authority && getThrough.authority.contractor_Menu == 1">
                  <a [routerLink]="['/authView/contractorview']" class="nav-link" title="All Contractors">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>All Contractors</span>
                  </a>
               </li>
               <li class="nav-item" *ngIf="getThrough.authority && getThrough.authority.contractor_superviser == 1">
                  <a [routerLink]="['/authView/superviserview']" class="nav-link" title="All Superviser">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>All Supervisers
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.contractor_issued_meters_range_menu == 1">
                  <a [routerLink]="['/authView/issuedMeters']" class="nav-link" title="Issued Meters">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Issued Meters
                     </span>
                  </a>
               </li>
               <li class="nav-item" *ngIf="getThrough.authority && getThrough.authority.contractor_progress == 1">
                  <a [routerLink]="['/authView/contractorProgress']" class="nav-link" title="Contractor Progress">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Contractor Progress
                     </span>
                  </a>
               </li>

               <li class="nav-itemn subrepLI"
                  *ngIf="getThrough.authority && getThrough.authority.Contractor_Sub_Reports == 1">
                  <a class="nav-link" href="#contractorReports" data-toggle="collapse" aria-expanded="false">
                     <i class="nc-icon nc-ruler-pencil"></i>
                     <p>
                        Reports
                        <b class="caret"></b>
                     </p>
                  </a>
                  <div class="collapse" id="contractorReports">
                     <ul class="nav submenuRep" id="Consumers"
                        *ngIf="getThrough.authority && getThrough.authority.Contractor_Sub_Reports == 1">
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.contractor_info_Report == 1">
                           <a [routerLink]="['/reports/contractorInfoReport']" title="All Contractor's Report"
                              class="nav-link">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>All Contractor Report
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.contractor_superviser == 1">
                           <a [routerLink]="['/reports/superwiserInfoReport']" title="All Supervisers Report"
                              class="nav-link">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>All Superviser Report
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.contractor_installed_meters_report == 1">
                           <a [routerLink]="['/reports/installedMetersReport']" title="All Installed Meters Report"
                              class="nav-link">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Valid Installed Meters Report
                              </span>
                           </a>
                        </li>
                     </ul>
                  </div>
               </li>
            </ul>
         </div>
      </li>
      <li class="nav-item"
         *ngIf="getThrough.authority && getThrough.authority.Consumer_Menu == 1 && enableSocietyChange">
         <a class="nav-link" data-toggle="collapse" href="#consumer">
            <i class="nc-icon nc-circle-09"></i>
            <p>
               Consumer's
               <b class="caret"></b>
            </p>
         </a>
         <div class="collapse" id="consumer">
            <ul class="nav">
               <li class="nav-item" *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_Add_Consumer == 1">
                  <a [routerLink]="['/addconsumer']" class="nav-link" title="All Consumers">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>All Consumers
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_Add_Consumer_Complaint== 1">
                  <a [routerLink]="['/conscomplaint']" class="nav-link" title="Consumer Complaint">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Consumer Complaint
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_Add_Consumer_Permissions == 1">
                  <a (click)="permConsumerAll()" [routerLink]="['/addconsumer']" class="nav-link"
                     title=" Set Consumer Permissions">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Set Consumers Permissions
                     </span>
                  </a>
               </li>
               <li class="nav-itemn subrepLI"
                  *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_Reports == 1">
                  <a class="nav-link" href="#Consumers_reports" data-toggle="collapse" aria-expanded="false">
                     <i class="nc-icon nc-ruler-pencil"></i>
                     <p>
                        Reports
                        <b class="caret"></b>
                     </p>
                  </a>
                  <div class="collapse" id="Consumers_reports">
                     <ul class="nav submenuRep" id="Consumers"
                        *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_Reports == 1">
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_All_Cons_Complaint_Report == 1">
                           <a [routerLink]="['/reports/consConsumerComplaint']" title="All Consumer's Complaint Report"
                              class="nav-link">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Consumer's Complaint
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_All_Cons_Info_Report == 1">
                           <a [routerLink]="['/reports/consConsumerInfo']" title="Consumers Information Report"
                              class="nav-link">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Consumer's Information
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_All_Cons_Login_Logout_Report == 1">
                           <a [routerLink]="['/reports/consConsumerLogin']" title="Consumer Login/Logout Report"
                              class="nav-link">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Consumer's Login/Logout
                              </span>
                           </a>
                        </li>

                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_All_Cons_Notifications_Report == 1">
                           <a [routerLink]="['/reports/consConsumerNotification']"
                              title="Consumer's Notification Report" class="nav-link">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Consumer's Notification
                              </span>
                           </a>
                        </li>
                     </ul>
                  </div>
               </li>
            </ul>
         </div>
      </li>
      <li class="nav-item"
         *ngIf="getThrough.authority && getThrough.authority.Meters_Menu == 1  && enableSocietyChange">
         <a class="nav-link" data-toggle="collapse" href="#setting">
            <i class="nc-icon nc-settings-gear-64"></i>
            <p>
               Settings
               <b class="caret"></b>
            </p>
         </a>
         <div class="collapse" id="setting">
            <ul class="nav" id="Meter">

               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Activity_On_Meter == 1">
                  <a [routerLink]="['/meteractivity']" class="nav-link" title="Activity On All Meters">

                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Activity On All Meters
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Meter == 1">
                  <a [routerLink]="['/meterconfig']" class="nav-link" title="Configure All Meters">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Configure All Meters
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Meter_Utility == 1">
                  <a [routerLink]="['/meterconfigUtility']" class="nav-link" title="Configure All Meters">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Configure All Meters
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Disabled_Meters == 1">
                  <a [routerLink]="['/disabledMeters']" class="nav-link" title="Disabled Meters">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Disabled Meters</span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Reporting_From_Meter == 1">
                  <a [routerLink]="['/meterreporting']" class="nav-link" title="Reporting From All Meters">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Reporting From All Meters
                     </span>
                  </a>
                  <!-- </li>
                  <li *ngIf="getThrough.authority && getThrough.authority.add_new_tariff_access == 1">
                    <a [routerLink]="['/rechargemeter']" >Recharge Meter</a>
                  </li>
                  <li *ngIf="getThrough.authority && getThrough.authority.add_new_tariff_access == 1">
                  <a href="#" >Adjustment Meter</a>
                  </li>-->

            </ul>
         </div>
      </li>
      <li class="nav-item"
         *ngIf="getThrough.authority && getThrough.authority.Billing_Integration == 1  && enableSocietyChange">
         <a class="nav-link" data-toggle="collapse" href="#setting">
            <i class="nc-icon nc-settings-gear-64"></i>
            <p>
               Bills
               <b class="caret"></b>
            </p>
         </a>
         <div class="collapse" id="setting">
            <ul class="nav" id="Meter">

               <li class="nav-item" *ngIf="getThrough.authority && getThrough.authority.Meters_Supply_Payments == 1">
                  <a [routerLink]="['/authView/meterSupplyPayments']" class="nav-link" title="Meter Supply Payments">

                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Meter Supply Payments
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.authority && getThrough.authority.Meters_Integration_Payments == 1">
                  <a [routerLink]="['/authView/integrationPayments']" class="nav-link" title="Integration Payments">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>Integration Payments
                     </span>
                  </a>
               </li>
               <li class="nav-item" *ngIf="getThrough.authority && getThrough.authority.Meters_Fms_Sim_Payments == 1">
                  <a [routerLink]="['/authView/fmsSimPayments']" class="nav-link" title="FMS/SIM Payments">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>FMS/SIM Payments</span>
                  </a>
               </li>
               <li class="nav-item" *ngIf="getThrough.authority && getThrough.authority.Meters_Hes_Payments == 1">
                  <a [routerLink]="['/authView/hesPayments']" class="nav-link" title="HES Payments">
                     <span class="sidebar-normal">
                        <i class="nc-icon nc-explore-2 subicn"></i>HES Payments
                     </span>
                  </a>
            </ul>
         </div>
      </li>
      <li class="nav-item"
         *ngIf="getThrough.authority && getThrough.authority.Meters_Reports == 1  && enableSocietyChange">
         <a class="nav-link settingn" data-toggle="collapse" href="#reportsinfo">
            <i class="nc-icon nc-badge"></i>
            <p>
               Reports
               <b class="caret"></b>
            </p>
         </a>
         <div class="collapse" id="reportsinfo" *ngIf="
         getThrough.authority && getThrough.authority.Meters_Reports == 1">

            <ul class="nav" id="Meter_reports" *ngIf="getThrough.authority && getThrough.authority.Meters_Reports == 1">

               <li class="nav-itemn subrepLI" *ngIf="
               getThrough.authority && getThrough.authority.Meters_Reports_Activity_Log_Report == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_Authority_Wise_Activity_Log_Report == 1">
                  <a class="nav-link settingn" href="#Authority_reports" data-toggle="collapse" aria-expanded="false">
                     <i class="nc-icon nc-ruler-pencil"></i>
                     <p>
                        Authority Reports
                        <b class="caret"></b>
                     </p>
                  </a>
                  <div class="collapse" id="Authority_reports">
                     <ul class="nav submenuRep" id="Authority_reports">
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Activity_Log_Report == 1">
                           <a [routerLink]="['/reports/activityLogReport']" title="All Authorities Activity Log"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>All Authorities Activity Log
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Authority_Wise_Activity_Log_Report == 1">
                           <a [routerLink]="['/reports/authWiseActivityLogReport']" title="Auth Wise Activity Log"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Auth Wise Activity Log
                              </span>
                           </a>
                        </li>
                     </ul>
                  </div>
               </li>
               <li class="nav-itemn subrepLI" *ngIf="
               getThrough.authority && getThrough.authority.Meters_Reports_Daily_Low_Balance_Report == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_Daily_Zero_Balance_Report == 1
               ">
                  <a class="nav-link settingn" href="#balance_reports" data-toggle="collapse" aria-expanded="false">
                     <i class="nc-icon nc-ruler-pencil"></i>
                     <p>
                        Balance Reports
                        <b class="caret"></b>
                     </p>
                  </a>
                  <div class="collapse" id="balance_reports">
                     <ul class="nav submenuRep" id="balance_reports">
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Daily_Low_Balance_Report == 1">
                           <a [routerLink]="['/reports/lowBalanceReport']" title="Low Balance Report"
                              class="nav-link settingn">

                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Low Balance Report
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Daily_Zero_Balance_Report == 1">
                           <a [routerLink]="['/reports/dailyZeroBalanceReport']" title="Zero Balance Report"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Zero Balance Report
                              </span>
                           </a>
                        </li>
                     </ul>
                  </div>
               </li>
               <li class="nav-itemn subrepLI" *ngIf="
               getThrough.authority && getThrough.authority.Meters_Reports_Bill_Creation_Report == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_Happy_Hours_Report == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_Load_Limit_Config_Report == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_Tariff_Config_Slab_Report == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_Meters_Teriff == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_Tariff_Config_Report == 1
               ">
                  <a class="nav-link settingn" href="#config_reports" data-toggle="collapse" aria-expanded="false">
                     <i class="nc-icon nc-ruler-pencil"></i>
                     <p>
                        Config Reports
                        <b class="caret"></b>
                     </p>
                  </a>
                  <div class="collapse" id="config_reports">
                     <ul class="nav submenuRep" id="config_reports">
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Bill_Creation_Report == 1">
                           <a [routerLink]="['/reports/billCreationConfigReport']" title="Bill Creation Configuration"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Bill Creation Config Report
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Happy_Hours_Report == 1">
                           <a [routerLink]="['/reports/happyHoursConfigReport']" title="Happy Hours Configuration"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Happy Hours Config Report
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Load_Limit_Config_Report == 1">
                           <a [routerLink]="['/reports/loadLimitConfigReport']" title="Load Limit Configuration"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Load Limit Config Report
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Tariff_Config_Slab_Report == 1">
                           <a [routerLink]="['/reports/slabTeriffConfigReport']" title="Slab Tariff Configuration"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Slab Tariff Config Report
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Meters_Teriff == 1">
                           <a [routerLink]="['/reports/Tariff']" title="Tariff" class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Tariff
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Tariff_Config_Report == 1">
                           <a [routerLink]="['/reports/teriffConfigReport']" title=" Tariff Configuration"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Tariff Config Report
                              </span>
                           </a>
                        </li>

                     </ul>
                  </div>
               </li>
               <li class="nav-itemn subrepLI" *ngIf="
               getThrough.authority && getThrough.authority.Meters_Reports_Meter_Wise_Billing_Data_Report == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_Daily_Unit_Consumption_Report == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_Daily_Unit_Energy_Report == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_Latest_Tamper_Data_Report == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_Current_Data_Report == 1 ||
               (getThrough.authority.Meters_Reports_Monthly_Unit_Consumption_Report == 1 && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View==1) ||
               (getThrough.authority && getThrough.authority.Meters_Reports_Monthly_Unit_Consumption_Report == 1 && getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View==1) ||
               getThrough.authority && getThrough.authority.Meters_Reports_Daily_OnGoing_Consumption_Report == 1
               ">
                  <a class="nav-link settingn" href="#data_reports" data-toggle="collapse" aria-expanded="false">
                     <i class="nc-icon nc-ruler-pencil"></i>
                     <p>
                        Data Reports
                        <b class="caret"></b>
                     </p>
                  </a>
                  <div class="collapse" id="data_reports">
                     <ul class="nav submenuRep" id="data_reports">

                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Meter_Wise_Billing_Data_Report == 1">
                           <a [routerLink]="['/reports/consumerBills']" title="Consumer Bills"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Consumer Bills
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Meter_Wise_Billing_Data_Report == 1 && this.subdivision== 87">
                           <a [routerLink]="['/reports/maintBills']" title="Consumer Bills" class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i> Maintenance Bills
                              </span>
                           </a>
                        </li>

                       
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Daily_Unit_Energy_Report == 1">
                           <a [routerLink]="['/reports/energyReport']" title="Daily Consumption"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Daily Energy
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Latest_Tamper_Data_Report == 1">
                           <a [routerLink]="['/reports/eventsReport']" title="Events Report" class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Events Report
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Event_Push_data_Report == 1">
                           <a [routerLink]="['/reports/eventPushReport']" title="Event Push Report"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Event Push Report
                              </span>
                           </a>
                        </li>
                        <li class="nav-item" *ngIf="getThrough.authority && getThrough.authority.Push_data_Report == 1">
                           <a [routerLink]="['/reports/pushDataCount']" title="Push Data Count"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Push Data Count
                              </span>
                           </a>
                        </li>
                        <li class="nav-item" *ngIf="getThrough.authority && getThrough.authority.Push_data_Report == 1">
                           <a [routerLink]="['/reports/pushDataReport']" title="Push Data Report"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Push Data Report
                              </span>
                           </a>
                        </li>

                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Current_Data_Report == 1">
                           <a [routerLink]="['/reports/instantaneousReport']" title="Instantaneous Report"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Instantaneous Report
                              </span>
                           </a>
                        </li>


                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Monthly_Unit_Consumption_Report == 1 && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
                           <a [routerLink]="['/reports/sumerisedBillingReport']" title="Monthly Consumption"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Monthly Consumption
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Monthly_Unit_Consumption_Report == 1 && getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View">

                           <a *ngIf="subdivision!=104" [routerLink]="['/reports/sumerisedBillingReport']"
                              title="Monthly Billing Report" class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Monthly Billing Report
                              </span>
                           </a>
                           <a *ngIf="subdivision==104" [routerLink]="['/reports/monthlyBillingReport']"
                              title="Monthly Billing Report" class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Monthly Billing Report
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Daily_OnGoing_Consumption_Report == 1">
                           <a [routerLink]="['/reports/ongoingConsumptionReport']" title="On-Going Consumption"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Block Load Survey
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                        *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Daily_Unit_Consumption_Report == 1">
                        <a [routerLink]="['/reports/consumptionReport']" title="Daily Load Survey"
                           class="nav-link settingn">
                           <span class="sidebar-normal">
                              <i class="nc-icon nc-explore-2 subicn"></i>Daily Load Survey
                           </span>
                        </a>
                     </li>
                     </ul>
                  </div>
               </li>

               <li class="nav-itemn subrepLI" *ngIf="
               getThrough.authority && getThrough.authority.Meters_Reports_Daily_Total_Adjustment_Report == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_Daily_DG_Status_Report == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_Daily_Total_Adjustment_Report_Failed == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_Daily_Total_Revenue_Report_failed == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_Daily_Dead_Meters_Report == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_Daily_Total_Revenue_Report == 1
               ">
                  <a class="nav-link settingn" href="#trasction_reports" data-toggle="collapse" aria-expanded="false">
                     <i class="nc-icon nc-ruler-pencil"></i>
                     <p>
                        Transaction Reports
                        <b class="caret"></b>
                     </p>
                  </a>
                  <div class="collapse" id="trasction_reports">
                     <ul class="nav submenuRep" id="trasction_reports">
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Daily_Total_Adjustment_Report == 1">
                           <a [routerLink]="['/reports/adjustmentReport']" title="Adjustment Report"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Adjustment Report
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Daily_DG_Status_Report == 1">
                           <a [routerLink]="['/reports/dgStatusReport']" title="DG Status Report"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>DG Status Report
                              </span>
                           </a>
                        </li>

                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Daily_Total_Adjustment_Report_Failed == 1">
                           <a [routerLink]="['/reports/failedAdjustmentReport']" title="Failed Adjustment Report"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Failed Adjustment Report
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Daily_Total_Revenue_Report_failed == 1">
                           <a [routerLink]="['/reports/failedTransactionReport']" title="Failed Recharge Report"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Failed Recharge Report
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Daily_Dead_Meters_Report == 1">
                           <a [routerLink]="['/reports/deadMetersReport']" title="Dead Meters Report"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Inactive Meters Report
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Daily_Total_Revenue_Report == 1">
                           <a [routerLink]="['/reports/transactionReport']" title="Recharge Report"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Recharge Report
                              </span>
                           </a>
                        </li>
                     </ul>
                  </div>
               </li>

               <li class="nav-itemn subrepLI" *ngIf="
               getThrough.authority && getThrough.authority.Meters_Reports_Day_Wise_Meters_Information_Report == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_Day_Wise_Non_Comunicated_Report == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_Meters_Information_Report == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_Old_New_Meters_Information_Report == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_Replaced_Meters_Information_Report == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_Replaced_Meters_History_Report == 1 ||
               getThrough.authority && getThrough.authority.Comulative_Fault_Report == 1 ||
               getThrough.authority && getThrough.authority.Removed_Meters_Report == 1 ||
               getThrough.authority && getThrough.authority.Removed_Meter_Report == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_PD_Meters_Report == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_SMS_Sent_Report == 1 ||
               getThrough.authority && getThrough.authority.Stock_Analysis_Report == 1 ||
               getThrough.authority && getThrough.authority.Meters_Reports_SLA_Report == 1 ||
               getThrough.authority && getThrough.authority.Admin_Non_Comm_Count == 1 ||
               getThrough.authority && getThrough.authority.User_Work_Info_Report == 1 ||
               getThrough.authority && getThrough.authority.smart_meter_order_report == 1
               ">
                  <a class="nav-link settingn" href="#other_reports" data-toggle="collapse" aria-expanded="false">
                     <i class="nc-icon nc-ruler-pencil"></i>
                     <p>
                        Other Reports
                        <b class="caret"></b>

                     </p>
                  </a>
                  <div class="collapse" id="other_reports">
                     <ul class="nav submenuRep" id="other_reports">
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Day_Wise_Meters_Information_Report == 1">
                           <a [routerLink]="['/reports/installedMeterSummery']" title="Day Wise Installation Progress"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Day Wise Installation Progress
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Day_Wise_Non_Comunicated_Report == 1">
                           <a [routerLink]="['/reports/nonComunicatedMeterSummery']" title="Day Wise Non Communicated"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Day Wise Non Communicated
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Meters_Information_Report == 1">
                           <a [routerLink]="['/reports/meterInformationReport']" title="Meter's Information"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Meter's Information
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Old_New_Meters_Information_Report == 1">
                           <a [routerLink]="['/reports/oldNewMeterInfo']" title="Old/New Meter Information"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Old/New Meter Information
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Removed_Meter_Report == 1">
                           <a [routerLink]="['/reports/pspclRemovedMeters']" title="Removed Meters"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Removed Meters By Auth
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Removed_Meter_Report == 1">
                           <a [routerLink]="['/reports/inactiveMeters']" title="Removed By IT"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Removed Meters By IT
                              </span>
                           </a>
                        </li>
                        <li class="nav-item" *ngIf="getThrough.authority && getThrough.authority.Auth_Check_Meter == 1">
                           <a [routerLink]="['/reports/checkMeter']" title="Check Meters" class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Check Meters
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Old_New_Meters_Billing_Report == 1">
                           <a [routerLink]="['/reports/billingInfoReport']" title="Billing Information"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Billing Information
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Replaced_Meters_Information_Report == 1">
                           <a [routerLink]="['/reports/smartMeterReplacement']" title="SM Replacement"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>SM Replacement
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Replaced_Meters_History_Report == 1">
                           <a [routerLink]="['/reports/smartMeterDefectiveReplacement']"
                              title="SM Defective Replacement" class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i> SM Defective Replacement
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Comulative_Fault_Report == 1">
                           <a [routerLink]="['/reports/CumulativeMeterIssuesReport']"
                              title="Cumulative Meter Issues Report" class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i> Cumulative Meter Issues Report
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Removed_Meters_Report == 1">
                           <a [routerLink]="['/reports/releasedMetersReport']" title="Released Meters Report"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i> Released Meters Report
                              </span>
                           </a>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_PD_Meters_Report == 1">
                           <a [routerLink]="['/reports/pdMetersInfo']" title="PD/Replaced Meters Report"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i> PD/Replaced Meters Report
                              </span>
                           </a>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Stock_Analysis_Report == 1">
                           <a [routerLink]="['/reports/stockAnalysis']" title="Stock Analysis Report"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i> Stock Analysis Report
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.User_Work_Info_Report == 1">
                           <a [routerLink]="['/reports/userWorkInfo']" title="User Work Info Report"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i> User Work Info Report
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Admin_Non_Comm_Count == 1">
                           <a [routerLink]="['/reports/nonComCount']" title="Non-Communication Count"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i> Non-Communication Count
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_SMS_Sent_Report == 1">
                           <a [routerLink]="['/reports/smsSentReport']" title="SMS Sent Report"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>SMS Sent Report
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_SLA_Report == 1">
                           <a [routerLink]="['/reports/slaReport']" title="SLA Report" class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>SLA Report
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_SLA_Govt_Report == 1">
                           <a [routerLink]="['/reports/slaGovtReport']" title="SLA Report" class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Govt. Meters SLA Report
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_SLA_Pvt_Report == 1">
                           <a [routerLink]="['/reports/slaPvtReport']" title="SLA Report" class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Pvt. Meters SLA Report
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.smart_meter_order_report == 1">
                           <a [routerLink]="['/reports/smartMeterOrderReport']" title="Smart Meter Order Report"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Smart Meter Order Report
                              </span>
                           </a>
                        </li>
                        <li class="nav-item"
                           *ngIf="getThrough.authority && getThrough.authority.smart_meter_order_report == 1">
                           <a [routerLink]="['/reports/dispatchedMeters']" title="Dispatched Meters Report"
                              class="nav-link settingn">
                              <span class="sidebar-normal">
                                 <i class="nc-icon nc-explore-2 subicn"></i>Dispatched Meters Report
                              </span>
                           </a>
                        </li>
                     </ul>
                  </div>
               </li>


            </ul>
         </div>
      </li>
   </ul>


   <!--<ul class="navigation-menu makeToggle" *ngIf="getThrough.authority">-->
   <ul class="navigation-menu" *ngIf="getThrough.authority" style="display:none;">
      <li *ngIf="!menuCheck">
         <!--<a href="#desh" data-toggle="collapse" aria-expanded="false" >-->
         <a href="#desh" data-toggle="collapse">
            <span class="link-title">Dashboard</span>
            <i class="material-icons d-c3">
               home
            </i>
         </a>
         <ul class="navigation-submenu" id="desh">
            <li>
               <a [routerLink]="['authBaseURl']" class="sbu-menu-icon" title="Graphical Dashboard">
                  <i class="material-icons d-c7">
                     analytics
                  </i>
                  <span class="link-title">Graphical Dashboard</span>
               </a>
            </li>
            <li>
               <a [routerLink]="['/gridDeshBoard']" class="sbu-menu-icon" title="Grid Dashboard">
                  <i class="material-icons d-c8">
                     view_comfy
                  </i>
                  <span class="link-title">Grid Dashboard</span>
               </a>
            </li>
         </ul>
      </li>
      <li *ngIf="menuCheck">
         <!--<a href="#desh" data-toggle="collapse" aria-expanded="false" >-->
         <a [routerLink]="['deshauth']" data-toggle="collapse">
            <span class="link-title">Dashboard</span>
            <i class="material-icons d-c3">
               home
            </i>
         </a>
      </li>
      <li *ngIf="getThrough.authority && getThrough.authority.Auth_Menu == 1 ">
         <a href="#Authority" data-toggle="collapse" aria-expanded="false">
            <span class="link-title">Authority's</span>
            <i class="material-icons d-c5">
               supervised_user_circle
            </i>
         </a>
         <ul class="collapse navigation-submenu" id="Authority">
            <li *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_New_Level == 1">
               <a [routerLink]="['/authView/authorityaddlevels']" class="sbu-menu-icon" title="Level's">
                  <i class="material-icons d-c2">
                     assessment
                  </i>
                  <span class="link-title">Level's</span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_Authority == 1">
               <a [routerLink]="['/authView/authorityview']" class="sbu-menu-icon" title="Authority's">
                  <i class="material-icons d-c3">
                     supervised_user_circle
                  </i>
                  <span class="link-title">All Authorities</span>
               </a>
            </li>
            <li
               *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_Meter_Firmware == 1 && enableSocietyChange">
               <a [routerLink]="['/authView/meterFWVersion']" class="sbu-menu-icon" title="Meter FW Version">
                  <i class="material-icons d-c4">
                     developer_mode
                  </i>
                  <span class="link-title">
                     Meter FW Version
                  </span>
               </a>
            </li>
            <li
               *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_Meter_Range == 1 && enableSocietyChange">
               <a [routerLink]="['/authView/meterRange']" class="sbu-menu-icon" title="Meter Range">
                  <i class="material-icons d-c6">
                     low_priority
                  </i>
                  <span class="link-title">
                     Meter Range
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_DG == 1 && enableSocietyChange">
               <a [routerLink]="['/authView/addDG']" class="sbu-menu-icon" title="DG's">
                  <i class="material-icons d-c7">
                     backup
                  </i>
                  <span class="link-title">
                     DG's
                  </span>
               </a>
            </li>
            <li
               *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_Tower == 1 && enableSocietyChange">
               <a [routerLink]="['/authView/addTowers']" class="sbu-menu-icon" title="Tower's">
                  <i class="material-icons d-c8">
                     storage
                  </i>
                  <span class="link-title">
                     Tower's
                  </span>
               </a>
            </li>
            <li
               *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_Group == 1 && enableSocietyChange">
               <a [routerLink]="['/authView/addGroups']" class="sbu-menu-icon" title="Group's">
                  <i class="material-icons d-c9">
                     group_work
                  </i>
                  <span class="link-title">
                     Group's
                  </span>
               </a>
            </li>
            <li
               *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_Teriff == 1 && enableSocietyChange">
               <a [routerLink]="['/authView/authorityaddteriff']" class="sbu-menu-icon" title="Tariff's">
                  <i class="material-icons d-c10">
                     chrome_reader_mode
                  </i>
                  <span class="link-title">
                     Tariff's
                  </span>
               </a>
            </li>
            <li
               *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_Consumer_Notification == 1 && enableSocietyChange">
               <a [routerLink]="['/authView/addconsumernotification']" class="sbu-menu-icon"
                  title="Consumer's Notification">
                  <i class="material-icons d-c11">
                     notification_important
                  </i>
                  <span class="link-title">
                     Consumer's Notification
                  </span>
               </a>
            </li>
            <li
               *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_New_Meter_Command == 1 && enableSocietyChange">
               <a [routerLink]="['/authView/addmetercommand']" class="sbu-menu-icon" title="Meter's Command">
                  <i class="material-icons d-c12">
                     dns
                  </i>
                  <span class="link-title">
                     Meter's Command
                  </span>
               </a>
            </li>
            <li
               *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_New_DCU_Command == 1 && enableSocietyChange">
               <a [routerLink]="['/authView/adddcucommand']" class="sbu-menu-icon" title="DCU's Command">
                  <i class="material-icons d-c13">
                     dns
                  </i>
                  <span class="link-title">
                     DCU's Command
                  </span>
               </a>
            </li>
            <li
               *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Add_New_Scheduler == 1 && enableSocietyChange">
               <a [routerLink]="['/authView/addSchedular']" class="sbu-menu-icon" title="Add Schedular">
                  <i class="material-icons d-c2">
                     schedule
                  </i>
                  <span class="link-title">
                     Add Scheduler
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Refund == 1 && enableSocietyChange">
               <a [routerLink]="['/authView/consumerRefund']" class="sbu-menu-icon" title="consumerRefund">
                  <i class="material-icons d-c5">
                     restore
                  </i>
                  <span class="link-title">
                     Consumer Refund
                  </span>
               </a>
            </li>
            <li
               *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Pending_Demands == 1 && enableSocietyChange">
               <a [routerLink]="['/authView/pendingCommands']" class="sbu-menu-icon" title="Pending Commands">
                  <i class="material-icons d-c2">
                     pending_actions
                  </i>
                  <span class="link-title">
                     Pending Commands
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Reports == 1 && enableSocietyChange">
               <a href="#Authority" aria-expanded="false">
                  <i class="material-icons d-c3">assignment</i>
                  <span class="link-title">Reports</span>
               </a>
               <ul class="collapse navigation-submenu" id="Authority"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Reports == 1">
                  <li
                     *ngIf="getThrough.authority && (getThrough.authority.Auth_Sub_Menu_Sub_All_Authority_Info_Report == 1 || getThrough.authority.Auth_Sub_Menu_Sub_All_Authority_Wise_Info_Report == 1)">
                     <a [routerLink]="['/reports/authAllInfo']" title="All Authority Info Report" class="sbu-menu-icon">
                        <i class="material-icons d-c2">
                           report
                        </i>
                        <span class="link-title">
                           Auth's Info
                        </span>
                     </a>
                  </li>
                  <!--<li *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_All_Authority_Wise_Login_Logout_Report == 1">
                  <a [routerLink]="['/reports/authWiseLoginInfo']" title="Authority-Wise Login/Logout Report" class="sbu-menu-icon">
                    <i class="material-icons d-c5">
                      report
                    </i>
                    <span class="link-title">
                      Auth-Wise Login/Logout  
                    </span>
                  </a>
                  </li>-->
                  <li
                     *ngIf="getThrough.authority && (getThrough.authority.Auth_Sub_Menu_Sub_All_Authority_Login_Logout_Report == 1 || getThrough.authority.Auth_Sub_Menu_Sub_All_Authority_Wise_Login_Logout_Report == 1)">
                     <a [routerLink]="['/reports/authAllLoginInfo']" title="Authority Login/Logout Report"
                        class="sbu-menu-icon">
                        <i class="material-icons d-c5">
                           report
                        </i>
                        <span class="link-title">
                           Auth's Login/Logout
                        </span>
                     </a>
                  </li>
                  <!--<li *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_All_Country_Info_Report == 1">
                  <a [routerLink]="['/reports/authCountryInfo']" title="All Country Info Report" class="sbu-menu-icon">
                    <i class="material-icons d-c3">
                      report
                    </i>
                    <span class="link-title">
                      Country's Info  
                    </span>
                  </a>
                  </li>
                  <li *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_All_State_Info_Report == 1">
                  <a [routerLink]="['/reports/authCountryStateInfo']" title="All State Info Report" class="sbu-menu-icon">
                    <i class="material-icons d-c6">
                      report
                    </i>
                    <span class="link-title">
                      State's Info 
                    </span>
                  </a>
                  </li>
                  <li *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_All_Utility_Info_Report == 1">
                  <a [routerLink]="['/reports/authCountryUtilityInfo']" title="All Utility Info Report" class="sbu-menu-icon">
                    <i class="material-icons d-c4">
                      report
                    </i>
                    <span class="link-title">
                      Utility's Info  
                    </span>
                  </a>
                  </li>
                  <li *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_All_Circle_Info_Report == 1">
                  <a [routerLink]="['/reports/authCircleInfo']" title="All Circle Info Report" class="sbu-menu-icon">
                    <i class="material-icons d-c2">
                      report
                    </i>
                    <span class="link-title">
                      Circle's Info  
                    </span>
                  </a>
                  </li>
                  <li *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_All_Division_Info_Report == 1">
                  <a [routerLink]="['/reports/authDivisionInfo']" title="All Division Info Report" class="sbu-menu-icon">
                    <i class="material-icons d-c8">
                      report
                    </i>
                    <span class="link-title">
                      Division's Info  
                    </span>
                  </a>
                  </li>
                  <li *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_All_Sub_Division_Info_Report == 1">
                  <a [routerLink]="['/reports/authSubDivisionInfo']" title="All Sub-Division Info Report" class="sbu-menu-icon">
                    <i class="material-icons d-c4">
                      report
                    </i>
                    <span class="link-title">
                      Sub-Division's Info  
                    </span>
                  </a>
                  </li>
                  <li *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Electricity_Tariff_History == 1">
                  <a [routerLink]="['/reports/authElectTariffInfo']" title="Electricity Tariff History" class="sbu-menu-icon">
                      <i class="material-icons d-c2">
                        report
                      </i>
                      <span class="link-title">
                        Elect.Tariff History
                      </span> 
                    </a>
                  </li>-->
               </ul>
            </li>
         </ul>
      </li>
      <li *ngIf="getThrough.authority && getThrough.authority.DCU_Menu == 1 && enableSocietyChange">
         <a href="#DCU" data-toggle="collapse" aria-expanded="false">
            <span class="link-title">DCU's</span>
            <i class="material-icons d-c2">
               settings_remote
            </i>adshboard
         </a>
         <ul class="collapse navigation-submenu" id="DCU">
            <li *ngIf="getThrough.authority && getThrough.authority.DCU_Sub_Configure_DCU == 1">
               <a [routerLink]="['/dcuconfig']" class="sbu-menu-icon">
                  <i class="material-icons d-c8">
                     build
                  </i>
                  <span class="link-title">
                     Configure DCU
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.DCU_Sub_Activity_On_DCU == 1">
               <a [routerLink]="['/dcuactivity']" class="sbu-menu-icon">
                  <i class="material-icons d-c7">
                     chrome_reader_mode
                  </i>
                  <span class="link-title">
                     Activity On DCU
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.DCU_Sub_Reporting_From_DCU == 1">
               <a [routerLink]="['/dcureporting']" class="sbu-menu-icon">
                  <i class="material-icons d-c6">
                     eco
                  </i>
                  <span class="link-title">
                     Reporting From DCU
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.DCU_Sub_Reports == 1">
               <a href="#DCU_reports" data-toggle="collapse" aria-expanded="false">
                  <i class="material-icons d-c3">assignment</i>
                  <span class="link-title">Reports</span>
               </a>
               <ul class="collapse navigation-submenu" id="DCU_reports"
                  *ngIf="getThrough.authority && getThrough.authority.DCU_Sub_Reports == 1">
                  <li *ngIf="getThrough.authority && getThrough.authority.DCU_Sub_Reports_Sub_All_DCU_Info_Report == 1">
                     <a [routerLink]="['/reports/dcuInformation']" title="All DCU Report" class="sbu-menu-icon">
                        <i class="material-icons d-c7">
                           report
                        </i>
                        <span class="link-title">
                           All DCU's Info
                        </span>
                     </a>
                  </li>
                  <li
                     *ngIf="getThrough.authority && getThrough.authority.DCU_Sub_Reports_Sub_All_DCU_Gateway_Config_Report == 1">
                     <a [routerLink]="['/reports/dcuGatewayConfig']" title="DCU's Gateway Config Report"
                        class="sbu-menu-icon">
                        <i class="material-icons d-c2">
                           report
                        </i>
                        <span class="link-title">
                           DCU's Gateway Config
                        </span>
                     </a>
                  </li>
                  <li
                     *ngIf="getThrough.authority && getThrough.authority.DCU_Sub_Reports_Sub_All_DCU_Scheduled_Demand_Report == 1">
                     <a [routerLink]="['/reports/dcuScheduleddemand']" title="Scheduled Demand Report"
                        class="sbu-menu-icon">
                        <i class="material-icons d-c3">
                           report
                        </i>
                        <span class="link-title">
                           Scheduled Demand
                        </span>
                     </a>
                  </li>
                  <li
                     *ngIf="getThrough.authority && getThrough.authority.DCU_Sub_Reports_Sub_All_DCU_Configured_Server_Details_Report == 1">
                     <a [routerLink]="['/reports/dcuConfiguredServerInfo']" title="Configured Server Info Report"
                        class="sbu-menu-icon">
                        <i class="material-icons d-c5">
                           report
                        </i>
                        <span class="link-title">
                           Configured Server Info
                        </span>
                     </a>
                  </li>
                  <li *ngIf="getThrough.authority && getThrough.authority.DCU_Sub_Reports_Sub_All_DCU_RTC_Report == 1">
                     <a [routerLink]="['/reports/dcuRtc']" title="DCU's RTC Report" class="sbu-menu-icon">
                        <i class="material-icons d-c4">
                           report
                        </i>
                        <span class="link-title">
                           DCU's RTC
                        </span>
                     </a>
                  </li>
               </ul>
            </li>
         </ul>
      </li>
      <li *ngIf="getThrough.authority && getThrough.authority.Consumer_Menu == 1 && enableSocietyChange">
         <a href="#Consumers" data-toggle="collapse" aria-expanded="false">
            <span class="link-title">Consumer's</span>
            <i class="material-icons d-c7">
               supervisor_account
            </i>
         </a>
         <ul class="collapse navigation-submenu" id="Consumers">
            <li *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_Add_Consumer == 1">
               <a [routerLink]="['/addconsumer']" class="sbu-menu-icon" title="All Consumers">
                  <i class="material-icons d-c5">
                     person_add
                  </i>
                  <span class="link-title">
                     All Consumers
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_Add_Consumer_Permissions == 1">
               <a (click)="permConsumerAll()" [routerLink]="['/addconsumer']" class="sbu-menu-icon"
                  title=" Set Consumer Permissions">
                  <i class="material-icons d-c9">
                     perm_data_setting
                  </i>
                  <span class="link-title">
                     Set Consumers Permissions
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_Add_Consumer_Complaint== 1">
               <a [routerLink]="['/conscomplaint']" class="sbu-menu-icon" title="Consumer Complaint">
                  <i class="material-icons d-c6">
                     horizontal_split
                  </i>
                  <span class="link-title">
                     Consumer Complaint
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_Reports == 1">
               <a href="#Consumers_reports" data-toggle="collapse" aria-expanded="false">
                  <i class="material-icons d-c3">assignment</i>
                  <span class="link-title">Reports</span>
               </a>
               <ul class="collapse navigation-submenu" id="Consumers"
                  *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_Reports == 1">
                  <li *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_All_Cons_Info_Report == 1">
                     <a [routerLink]="['/reports/consConsumerInfo']" title="Consumers Information Report"
                        class="sbu-menu-icon">
                        <i class="material-icons d-c5">
                           report
                        </i>
                        <span class="link-title">
                           Consumer's Information
                        </span>
                     </a>
                  </li>
                  <li
                     *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_All_Cons_Login_Logout_Report == 1">
                     <a [routerLink]="['/reports/consConsumerLogin']" title="Consumer Login/Logout Report"
                        class="sbu-menu-icon">
                        <i class="material-icons d-c4">
                           report
                        </i>
                        <span class="link-title">
                           Consumer's Login/Logout
                        </span>
                     </a>
                  </li>
                  <li *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_All_Cons_Complaint_Report == 1">
                     <a [routerLink]="['/reports/consConsumerComplaint']" title="All Consumer's Complaint Report"
                        class="sbu-menu-icon">
                        <i class="material-icons d-c3">
                           report
                        </i>
                        <span class="link-title">
                           Consumer's Complaint
                        </span>
                     </a>
                  </li>
                  <li
                     *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_All_Cons_Notifications_Report == 1">
                     <a [routerLink]="['/reports/consConsumerNotification']" title="Consumer's Notification Report"
                        class="sbu-menu-icon">
                        <i class="material-icons d-c2">
                           report
                        </i>
                        <span class="link-title">
                           Consumer's Notification
                        </span>
                     </a>
                  </li>
               </ul>
            </li>
         </ul>
      </li>
      <li *ngIf="getThrough.authority && getThrough.authority.Meters_Menu == 1  && enableSocietyChange">
         <a href="#Meter" data-toggle="collapse" aria-expanded="false">
            <span class="link-title">
               Settings
            </span>
            <i class="material-icons d-c2">
               build
            </i>
         </a>

         <ul class="collapse navigation-submenu" id="Meter">
            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Activity_On_Meter == 1">
               <a [routerLink]="['/meteractivity']" class="sbu-menu-icon" title="Activity On All Meters">
                  <i class="material-icons d-c3">
                     chrome_reader_mode
                  </i>
                  <span class="link-title">
                     Activity On All Meters
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Meter == 1">
               <a [routerLink]="['/meterconfig']" class="sbu-menu-icon" title="Configure All Meters">
                  <i class="material-icons d-c2">
                     build
                  </i>
                  <span class="link-title">
                     Configure All Meters
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Disabled_Meters == 1">
               <a [routerLink]="['/disabledMeters']" class="sbu-menu-icon" title="Disabled Meters">
                  <i class="material-icons d-c7">
                     cloud_off
                  </i>
                  <span class="link-title">Disabled Meters</span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Reporting_From_Meter == 1">
               <a [routerLink]="['/meterreporting']" class="sbu-menu-icon" title="Reporting From All Meters">
                  <i class="material-icons d-c4">
                     eco
                  </i>
                  <span class="link-title">
                     Reporting From All Meters
                  </span>
               </a>
               <!-- </li>
               <li *ngIf="getThrough.authority && getThrough.authority.add_new_tariff_access == 1">
                 <a [routerLink]="['/rechargemeter']" >Recharge Meter</a>
               </li>
               <li *ngIf="getThrough.authority && getThrough.authority.add_new_tariff_access == 1">
               <a href="#" >Adjustment Meter</a>
               </li>-->

         </ul>
      </li>
      <li *ngIf="getThrough.authority && getThrough.authority.Meters_Reports == 1  && enableSocietyChange">
         <a href="#Meter_reports" data-toggle="collapse" aria-expanded="false">
            <span class="link-title">Reports</span>
            <i class="material-icons d-c3">
               assignment
            </i>
         </a>
         <ul class="collapse navigation-submenu" id="Meter_reports"
            *ngIf="getThrough.authority && getThrough.authority.Meters_Reports == 1">
            <li
               *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Old_New_Meters_Information_Report == 1">
               <a [routerLink]="['/reports', 'oldNewMeterInfo']" title="Old/New Meter Information"
                  class="sbu-menu-icon">
                  <i class="material-icons d-c4">
                     report
                  </i>
                  <span class="link-title">
                     Old/New Meter Information
                  </span>
               </a>
            </li>
            <li
               *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Old_New_Meters_Information_Report == 1">
               <a [routerLink]="['/reports', 'installedMeterSummery']" title="Installed Summery Report"
                  class="sbu-menu-icon">
                  <i class="material-icons d-c4">
                     report
                  </i>
                  <span class="link-title">
                     Installed Meter Summery
                  </span>
               </a>
            </li>

            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Meters_Information_Report == 1">
               <a [routerLink]="['/reports', 'meterInformationReport']" title="Meter's Information"
                  class="sbu-menu-icon">
                  <i class="material-icons d-c4">
                     report
                  </i>
                  <span class="link-title">
                     Meter's Information
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Meters_Teriff == 1">
               <a [routerLink]="['/reports/Tariff']" title="Tariff" class="sbu-menu-icon">
                  <i class="material-icons d-c5">
                     report
                  </i>
                  <span class="link-title">
                     Tariff
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Activity_Log_Report == 1">
               <a [routerLink]="['/reports/activityLogReport']" title="All Authorities Activity Log"
                  class="sbu-menu-icon">
                  <i class="material-icons d-c6">
                     report
                  </i>
                  <span class="link-title">
                     All Authorities Activity Log
                  </span>
               </a>
            </li>
            <li
               *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Authority_Wise_Activity_Log_Report == 1">
               <a [routerLink]="['/reports/authWiseActivityLogReport']" title="Auth Wise Activity Log"
                  class="sbu-menu-icon">
                  <i class="material-icons d-c7">
                     report
                  </i>
                  <span class="link-title">
                     Auth Wise Activity Log
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Current_Data_Report == 1">
               <a [routerLink]="['/reports/instantaneousReport']" title="Instantaneous Report" class="sbu-menu-icon">
                  <i class="material-icons d-c8">
                     report
                  </i>
                  <span class="link-title">
                     Instantaneous Report
                  </span>
               </a>
            </li>
            <li
               *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Daily_OnGoing_Consumption_Report == 1">
               <a [routerLink]="['/reports/ongoingConsumptionReport']" title="On-Going Consumption"
                  class="sbu-menu-icon">
                  <i class="material-icons d-c9">
                     report
                  </i>
                  <span class="link-title">
                     On-Going Consumption
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Daily_Unit_Consumption_Report == 1">
               <a [routerLink]="['/reports/consumptionReport']" title="Daily Consumption" class="sbu-menu-icon">
                  <i class="material-icons d-c10">
                     report
                  </i>
                  <span class="link-title">
                     Daily Consumption
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Daily_Unit_Energy_Report == 1">
               <a [routerLink]="['/reports/energyReport']" title="Daily Consumption" class="sbu-menu-icon">
                  <i class="material-icons d-c10">
                     report
                  </i>
                  <span class="link-title">
                     Daily Energy
                  </span>
               </a>
            </li>
            <li
               *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Monthly_Unit_Consumption_Report == 1 && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
               <a [routerLink]="['/reports/sumerisedBillingReport']" title="Monthly Consumption" class="sbu-menu-icon">
                  <i class="material-icons d-c11">
                     report
                  </i>
                  <span class="link-title">
                     Monthly Consumption
                  </span>
               </a>
            </li>
            <li
               *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Monthly_Unit_Consumption_Report == 1 && getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View">
               <a [routerLink]="['/reports/sumerisedBillingReport']" title="Monthly Billing Report"
                  class="sbu-menu-icon">
                  <i class="material-icons d-c11">
                     report
                  </i>
                  <span class="link-title">
                     Monthly Billing Report
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Meter_Wise_Billing_Data_Report == 1">
               <a [routerLink]="['/reports/consumerBills']" title="Consumer Bills" class="sbu-menu-icon">
                  <i class="material-icons d-c12">
                     report
                  </i>
                  <span class="link-title">
                     Consumer Bills
                  </span>
               </a>
            </li>
            <li
               *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Meter_Wise_Billing_Data_Report == 1 && this.subdivision== 87">
               <a [routerLink]="['/reports/maintBills']" title="Maintenance Bills" class="sbu-menu-icon">
                  <i class="material-icons d-c12">
                     report
                  </i>
                  <span class="link-title">
                     Maintenance Bills
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Latest_Tamper_Data_Report == 1">
               <a [routerLink]="['/reports/eventsReport']" title="Events Report" class="sbu-menu-icon">
                  <i class="material-icons d-c13">
                     report
                  </i>
                  <span class="link-title">
                     Events Report
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Tariff_Config_Slab_Report == 1">
               <a [routerLink]="['/reports/teriffConfigReport']" title="Tariff Configuration" class="sbu-menu-icon">
                  <i class="material-icons d-c2">
                     report
                  </i>
                  <span class="link-title">
                     Tariff Configuration
                  </span>
               </a>
            </li>

            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Happy_Hours_Report == 1">
               <a [routerLink]="['/reports/happyHoursConfigReport']" title="Happy Hours Configuration"
                  class="sbu-menu-icon">
                  <i class="material-icons d-c15">
                     report
                  </i>
                  <span class="link-title">
                     Happy Hours Configuration
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Bill_Creation_Report == 1">
               <a [routerLink]="['/reports/billCreationConfigReport']" title="Bill Creation Configuration"
                  class="sbu-menu-icon">
                  <i class="material-icons d-c2">
                     report
                  </i>
                  <span class="link-title">
                     Bill Creation Configuration
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Load_Limit_Config_Report == 1">
               <a [routerLink]="['/reports/loadLimitConfigReport']" title="Load Limit Configuration"
                  class="sbu-menu-icon">
                  <i class="material-icons d-c3">
                     report
                  </i>
                  <span class="link-title">
                     Load Limit Configuration
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Daily_Total_Revenue_Report == 1">
               <a [routerLink]="['/reports/transactionReport']" title="Recharge Report" class="sbu-menu-icon">
                  <i class="material-icons d-c4">
                     report
                  </i>
                  <span class="link-title">
                     Recharge Report
                  </span>
               </a>
            </li>
            <li
               *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Daily_Total_Revenue_Report_failed == 1">
               <a [routerLink]="['/reports/failedTransactionReport']" title="Failed Recharge Report"
                  class="sbu-menu-icon">
                  <i class="material-icons d-c4">
                     report
                  </i>
                  <span class="link-title">
                     Failed Recharge Report
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Daily_Total_Adjustment_Report == 1">
               <a [routerLink]="['/reports/adjustmentReport']" title="Adjustment Report" class="sbu-menu-icon">
                  <i class="material-icons d-c5">
                     report
                  </i>
                  <span class="link-title">
                     Adjustment Report
                  </span>
               </a>
            </li>
            <li
               *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Daily_Total_Adjustment_Report_Failed == 1">
               <a [routerLink]="['/reports/failedAdjustmentReport']" title="Failed Adjustment Report"
                  class="sbu-menu-icon">
                  <i class="material-icons d-c5">
                     report
                  </i>
                  <span class="link-title">
                     Failed Adjustment Report
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Daily_DG_Status_Report == 1">
               <a [routerLink]="['/reports/dgStatusReport']" title="DG Status Report" class="sbu-menu-icon">
                  <i class="material-icons d-c6">
                     report
                  </i>
                  <span class="link-title">
                     DG Status Report
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Daily_Low_Balance_Report == 1">
               <a [routerLink]="['/reports/lowBalanceReport']" title="Low Balance Report" class="sbu-menu-icon">
                  <i class="material-icons d-c7">
                     report
                  </i>
                  <span class="link-title">
                     Low Balance Report
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Daily_Zero_Balance_Report == 1">
               <a [routerLink]="['/reports/dailyZeroBalanceReport']" title="Zero Balance Report" class="sbu-menu-icon">
                  <i class="material-icons d-c8">
                     report
                  </i>
                  <span class="link-title">
                     Zero Balance Report
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_Daily_Dead_Meters_Report == 1">
               <a [routerLink]="['/reports/deadMetersReport']" title="Dead Meters Report" class="sbu-menu-icon">
                  <i class="material-icons d-c9">
                     report
                  </i>
                  <span class="link-title">
                     Inactive Meters Report
                  </span>
               </a>
            </li>
            <li *ngIf="getThrough.authority && getThrough.authority.Meters_Reports_SMS_Sent_Report == 1">
               <a [routerLink]="['/reports/smsSentReport']" title="SMS Sent Report" class="sbu-menu-icon">
                  <i class="material-icons d-c10">
                     report
                  </i>
                  <span class="link-title">
                     SMS Sent Report
                  </span>
               </a>
            </li>
         </ul>
      </li>
   </ul>

   <!--<ul class="navigation-menu makeToggle" *ngIf="getThrough.consumer">-->
   <ul class="nav" *ngIf="getThrough.consumer">
      <li class="nav-item">
         <a class="nav-link" href="#desh" data-toggle="collapse">
            <i class="nc-icon nc-bank"></i>
            <p>
               Dashboard
               <b class="caret"></b>
            </p>
         </a>
         <div class="collapse" id="desh">
            <ul class="nav">
               <li class="nav-item">
                  <a [routerLink]="['dashboard']" class="nav-link">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Graphical Dashboard</span>
                  </a>
               </li>
               <li class="nav-item">
                  <a [routerLink]="['/gridDeshBoardCons']" class="nav-link">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Grid Dashboard</span>
                  </a>
               </li>
            </ul>
         </div>
      </li>
      <li class="nav-item" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Menu_Action_Access == 1">
         <a class="nav-link" (click)="activityOnMeter()" title="Actions" data-toggle="collapse">
            <i class="nc-icon nc-chart-bar-32"></i>
            <p>Actions</p>
         </a>
      </li>

      <li class="nav-item" *ngIf="getThrough.consumer && getThrough.consumer.Recharge_Menu_Access == 1">
         <a class="nav-link" [routerLink]="['/recharge']"
            [queryParams]="{currentuser: currentUser.authToken,typeData:1}" title="Recharge" data-toggle="collapse">
            <i class="fa fa-inr"></i>
            <p>Recharge
            </p>
         </a>
      </li>
      <li class="nav-item" *ngIf="getThrough.consumer && getThrough.consumer.Reports_Menu == 1">
         <a class="nav-link" href="#reports" data-toggle="collapse" aria-expanded="false" title="Report's">
            <i class="nc-icon nc-badge"></i>
            <p>Report's
               <b class="caret"></b>
            </p>
         </a>
         <div class="collapse" id="reports">
            <ul class="nav">
               <li class="nav-item"
                  *ngIf="getThrough.consumer && getThrough.consumer.Report_Adjustment_Data_Access == 1">
                  <a class="nav-link" [routerLink]="['/reports/adjustments']" title="Adjustments">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>
                        Adjustments
                     </span>
                  </a>
               </li>
               <li class="nav-item" *ngIf="getThrough.consumer && getThrough.consumer.Report_Bill_Data_Access == 1">
                  <a class="nav-link" [routerLink]="['/reports/billConsumer']" title="Electricity Bill">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>
                        Electricity Bills
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.consumer && getThrough.consumer.Report_Bill_Data_Access == 1 && this.subdivision== 87">
                  <a class="nav-link" [routerLink]="['/reports/maintBill']" title="Maintenance Bill">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>
                        Maintenance Bills
                     </span>
                  </a>
               </li>


               <li class="nav-item"
                  *ngIf="getThrough.consumer && getThrough.consumer.Reports_Complaint_History_Access == 1">
                  <a class="nav-link" [routerLink]="['/reports/comphistory']" title="Complaints History">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>
                        Complaints History
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.consumer && getThrough.consumer.Report_Daily_Consumption_Access == 1">
                  <a class="nav-link" [routerLink]="['/reports/loadsurvey']" title="Daily Consumption">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>
                        Daily Consumption
                     </span>
                  </a>
               </li>
               <li class="nav-item" *ngIf="getThrough.consumer && getThrough.consumer.Report_Event_Log_Access == 1">
                  <a class="nav-link" [routerLink]="['/reports/eventlog']" title="Event Log Report">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>
                        Event Log Report
                     </span>
                  </a>
               </li>
               <li class="nav-item" *ngIf="getThrough.consumer && getThrough.consumer.Reports_Login_Logout_Access == 1">
                  <a class="nav-link" [routerLink]="['/reports/consLoginLogout']" title="Login/Logout Report">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>
                        Login/Logout Report
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.consumer && getThrough.consumer.Report_Meters_Activity_Log_Access == 1">
                  <a class="nav-link" [routerLink]="['/reports/meteractivity']" title="Meter Activity Log">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>
                        Meter Activity Log
                     </span>
                  </a>
               </li>
               <li class="nav-item" *ngIf="getThrough.consumer && getThrough.consumer.Reports_Messages_Access == 1">
                  <a class="nav-link" [routerLink]="['/reports/notifihistory']" title="Messages">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>
                        Messages
                     </span>
                  </a>
               </li>
               <li class="nav-item"
                  *ngIf="getThrough.consumer && getThrough.consumer.Report_Monthly_Consumption_Access == 1">
                  <a class="nav-link" [routerLink]="['/reports/monthlyConsumption']" title="Monthly Consumption">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>
                        Monthly Consumption
                     </span>
                  </a>
               </li>

               <li class="nav-item" *ngIf="getThrough.consumer && getThrough.consumer.Report_On_Going_Access == 1">
                  <a class="nav-link" [routerLink]="['/reports/dipWiseloadsurvey']" title="On-Going Consumption">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>
                        On-Going Consumption
                     </span>
                  </a>
               </li>
               <li class="nav-item" *ngIf="getThrough.consumer && getThrough.consumer.Report_Recharge_Data_Access == 1">
                  <a class="nav-link" [routerLink]="['/reports/myrecharges']" title="Recharges">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Recharges</span>
                  </a>
               </li>
               <li class="nav-item" *ngIf="getThrough.consumer && getThrough.consumer.Reports_SMS_Sent_Access == 1">
                  <a class="nav-link" [routerLink]="['/reports/smsreports']" (click)="['/reports/smsreports']"
                     title="SMS Sent Report">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>
                        SMS Sent Report
                     </span>
                  </a>
               </li>



            </ul>
         </div>
      </li>

      <li class="nav-item" *ngIf="getThrough.consumer && getThrough.consumer.Setting_Menu_Access == 1">
         <a class="nav-link" href="#settings" data-toggle="collapse" aria-expanded="false">
            <i class="nc-icon nc-settings-gear-64"></i>
            <p>Settings
               <b class="caret"></b>
            </p>
         </a>
         <div class="collapse" id="settings">
            <ul class="nav">
               <li class="nav-item"
                  *ngIf="getThrough.consumer && getThrough.consumer.Setting_Current_Teriff_Access == 1">
                  <a class="nav-link" href="#" aria-expanded="false" (click)="allTeriff()" title="Current Tariff">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Current Tariff</span>
                  </a>
               </li>
            </ul>
         </div>
         <div class="collapse" id="settings">
            <ul class="nav">
               <li class="nav-item" *ngIf="getThrough.consumer && getThrough.consumer.Setting_load_limiter_Access == 1">
                  <a class="nav-link" href="#" aria-expanded="false" (click)="loadLimiter()" title="Load Limiter">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Load Limiter</span>
                  </a>
               </li>
            </ul>
         </div>
         <div class="collapse" id="settings">
            <ul class="nav">
               <li class="nav-item"
                  *ngIf="getThrough.consumer && getThrough.consumer.Setting_Notification_Setting_Access == 1">
                  <a class="nav-link" href="#" aria-expanded="false" (click)="allNotification()"
                     title="Notification Setting">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Notification Setting</span>
                  </a>
               </li>
            </ul>
         </div>
      </li>
      <li class="nav-item" *ngIf="getThrough.consumer && getThrough.consumer.Help_Menu_Access == 1">
         <a class="nav-link" class="nav-link" href="#complaints" data-toggle="collapse" aria-expanded="false">
            <i class="fa fa-question-circle"></i>
            <p>Help
               <b class="caret"></b>
            </p>
         </a>
         <div class="collapse" id="complaints">
            <ul class="nav">
               <li class="nav-item"
                  *ngIf="getThrough.consumer && getThrough.consumer.Help_Add_New_Complaint_Access == 1">
                  <a class="nav-link" href="#" (click)="consumerComplaints()" title="Add New Complaint">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>
                        Add New Complaint
                     </span>
                  </a>
               </li>
            </ul>
         </div>
         <div class="collapse" id="complaints">
            <ul class="nav">
               <li class="nav-item"
                  *ngIf="getThrough.consumer && getThrough.consumer.Help_Existing_Complaint_Access == 1">
                  <a class="nav-link" href="#" aria-expanded="false" (click)="allComplaints()"
                     title="Existing Complaints">
                     <span class="sidebar-normal"><i class="nc-icon nc-explore-2 subicn"></i>Existing Complaints</span>
                  </a>
               </li>
            </ul>
         </div>
      </li>
   </ul>
   <!-- <div class="rotatingPumpkin"> </div> -->
</div>