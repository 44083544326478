<div class="container-fluid innerpage_top page-content-wrapperr">
    <div class="row page-content-wrapper-innerr">
      <div class=" col-md-12 content-viewportt">
        <div class="page-content-wrapper-inner outerbox_shadow {{divClass}}">
  
          <!-- <ng-container class="tableloader col-sm-12" *ngIf="!isDataRedyForTableDcu">
                <div id="ajax-loader"></div>
                <div id="page-overlay"></div>
              </ng-container>  -->
          <ng-container *ngIf="isDataRedyForTableDcu  && tabToShow == 0">
            <div class="col-12">
              <div class="modal-header1">
                <h2>
                  Authority Information Report
                </h2>
              </div>
              <mat-card class="-mt-20">
                <mat-card-header class="h-16 xs-c-h">
                  <div class="title hidden-sm xs-dn" col-3>
                    DCU List
                  </div>
  
                  <div class="bg-card rounded-full border px-4 xs-no-p xs-no-mg xs-w-125" col-5>
                    <mat-form-field class="dcuFilter mat-form-field-infix" floatLabel="never">
                      <span matSuffix>
                        <mat-icon matSuffix class="d-c9">search</mat-icon>
                      </span>
                      <input type="text" matInput placeholder="Search Flat Id Or Meter No" value="{{dcuSearch}}"
                        (input)="onDcuSearchChange($event.target.value)" autocomplete="off">
                    </mat-form-field>
                  </div>
                  <span class="toolbar-space"></span>
                  <div col-3 col-xs-6>
                    <button type="button" [matMenuTriggerFor]="dcuListMenu" mat-button mat-button-base mat-primary
                      style="color: #000;" class="npMi">
                      <mat-icon class="d-c10">filter_list</mat-icon>
                    </button>
  
                    <button type="button" mat-mini-fab class="cPbg npMi" (click)="addMeter()"
                      *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Add_Meter">
                      <mat-icon class="d-c11">add</mat-icon>
                    </button>
  
                  </div>
                </mat-card-header>
                <style>
                  .table:not(.table-bordered) thead tr th,
                  .table:not(.table-bordered) tfoot tr th,
                  table:not(.table-bordered) thead tr th {
                    padding: 0px !important;
                  }
                </style>
                <mat-card-content>
  
                  <table mat-table [dataSource]="dataSource" class="table-responsivee table-responsive_fixed"
                    multiTemplateDataRows>
                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                      <th mat-header-cell *matHeaderCellDef>
  
                      </th>
                      <td mat-cell *matCellDef="let row" (click)="expandedElement = expandedElement === row ? null : row">
                        <button type="button" class="icon" (click)="meterView(row)">
                          <mat-icon class="d-c8">
                            {{expandedElement === row ? 'keyboard_arrow_down' : 'chevron_right'}}
                          </mat-icon>
                        </button>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="edit">
                      <th mat-header-cell *matHeaderCellDef>Action</th>
                      <td mat-cell *matCellDef="let row">
                        <button type="button" class="icon" [matMenuTriggerFor]="configureDcuMeter">
                          <mat-icon>menu_open</mat-icon>
                        </button>
                        <mat-menu #configureDcuMeter="matMenu" xPosition="before" class="notifications-dropdown">
                          <mat-list role="list">
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="dcuEditView(row, dcumodify)"
                                class="btn-block text-left">
                                <mat-icon class="d-c5">edit</mat-icon> Modify
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="dcuAddMeter(row.dcuID, dcumodify)"
                                class="btn-block text-left">
                                <mat-icon class="d-c6">build</mat-icon> Configure DCU
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="dcuAddMeter(row.dcuID, dcumodify)"
                                class="btn-block text-left">
                                <mat-icon class="d-c7">chrome_reader_mode</mat-icon> Actions On DCU
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="dcuAddMeter(row.dcuID, dcumodify)"
                                class="btn-block text-left">
                                <mat-icon class="d-c8">eco</mat-icon> Reporting From DCU
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="configureDcuMeter(row.dcuID)"
                                class="btn-block text-left">
                                <mat-icon class="d-c9">build</mat-icon> Configure Meters
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="dcuAddMeter(row.dcuID, dcumodify)"
                                class="btn-block text-left">
                                <mat-icon class="d-c10">chrome_reader_mode</mat-icon> Actions On Meters
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="dcuMeterReports(row.dcuID)"
                                class="btn-block text-left">
                                <mat-icon class="d-c11">eco</mat-icon> Download from Meters
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="dcuAddMeter(row.dcuID, dcumodify)"
                                class="btn-block text-left">
                                <mat-icon class="d-c12">all_inbox</mat-icon> Actions
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="dcuAddMeter(row.dcuID, dcumodify)"
                                class="btn-block text-left">
                                <mat-icon class="d-c13">assignment</mat-icon> Unit Consumption
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="dcuAddMeter(row.dcuID, dcumodify)"
                                class="btn-block text-left">
                                <mat-icon class="d-c14">assignment</mat-icon> Revenue Report
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="dcuAddMeter(row.dcuID, dcumodify)"
                                class="btn-block text-left">
                                <mat-icon>assignment</mat-icon> Low Balance Meter Report
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="dcuAddMeter(row.dcuID, dcumodify)"
                                class="btn-block text-left">
                                <mat-icon class="d-c15">assignment</mat-icon> Zero Balance Report
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="dcuAddMeter(row.dcuID, dcumodify)"
                                class="btn-block text-left">
                                <mat-icon class="d-c2">assignment</mat-icon> Dead Meters Report
                              </button>
                            </mat-list-item>
  
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="dcuAddMeter(row.dcuID, dcumodify)"
                                class="btn-block text-left">
                                <mat-icon class="d-c3">assignment</mat-icon> DG Status Report
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="dcuAddMeter(row.dcuID, dcumodify)"
                                class="btn-block text-left">
                                <mat-icon class="d-c4">assignment</mat-icon> Meter Information Report
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="dcuAddMeter(row.dcuID, dcumodify)"
                                class="btn-block text-left">
                                <mat-icon class="d-c5">assignment</mat-icon> Meter Activity Report
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="dcuAddMeter(row.dcuID, dcumodify)"
                                class="btn-block text-left">
                                <mat-icon class="d-c6">assignment</mat-icon> Meter Configuration Report
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="dcuRemoveView(row)" class="btn-block text-left">
                                <mat-icon class="d-c7">delete</mat-icon> Delete
                              </button>
                            </mat-list-item>
                          </mat-list>
                        </mat-menu>
                      </td>
                    </ng-container>
                    <!-- Name Column -->
                    <ng-container matColumnDef="dcuName">
                      <th mat-header-cell *matHeaderCellDef class="bolder"> Name </th>
                      <td mat-cell *matCellDef="let element" class="bolder"> {{element.dcuName}} </td>
                    </ng-container>
  
                    <!-- Weight Column -->
                    <ng-container matColumnDef="dcuCode">
                      <th mat-header-cell *matHeaderCellDef class="bolder"> Code </th>
                      <td mat-cell *matCellDef="let element" class="bolder"> {{element.dcuCode}} </td>
                    </ng-container>
  
                    <!-- Weight Column -->
                    <ng-container matColumnDef="dcuID">
                      <th mat-header-cell *matHeaderCellDef class="bolder"> ID </th>
                      <td mat-cell *matCellDef="let element" class="bolder"> {{element.dcuID}} </td>
                    </ng-container>
                    <!-- Weight Column -->
                    <ng-container matColumnDef="aID">
                      <th mat-header-cell *matHeaderCellDef class="bolder"> Authority Name </th>
                      <td mat-cell *matCellDef="let element" class="bolder"> {{element.aID}} </td>
                    </ng-container>
                    <!-- Weight Column -->
                    <ng-container matColumnDef="cID">
                      <th mat-header-cell *matHeaderCellDef class="bolder"> Country Name </th>
                      <td mat-cell *matCellDef="let element" class="bolder"> {{element.cID}} </td>
                    </ng-container>
                    <!-- Weight Column -->
                    <ng-container matColumnDef="sID">
                      <th mat-header-cell *matHeaderCellDef class="bolder"> State Name </th>
                      <td mat-cell *matCellDef="let element" class="bolder"> {{element.sID}} </td>
                    </ng-container>
                    <!-- Weight Column -->
                    <ng-container matColumnDef="uID">
                      <th mat-header-cell *matHeaderCellDef class="bolder"> Utility Name </th>
                      <td mat-cell *matCellDef="let element" class="bolder"> {{element.uID}} </td>
                    </ng-container>
                    <!-- Weight Column -->
                    <ng-container matColumnDef="ciID">
                      <th mat-header-cell *matHeaderCellDef class="bolder"> Circle Name </th>
                      <td mat-cell *matCellDef="let element" class="bolder"> {{element.ciID}} </td>
                    </ng-container>
                    <!-- Weight Column -->
                    <ng-container matColumnDef="dID">
                      <th mat-header-cell *matHeaderCellDef class="bolder"> Division Name </th>
                      <td mat-cell *matCellDef="let element" class="bolder"> {{element.dID}} </td>
                    </ng-container>
                    <!-- Weight Column -->
                    <ng-container matColumnDef="sdID">
                      <th mat-header-cell *matHeaderCellDef class="bolder"> Sub Division Name </th>
                      <td mat-cell *matCellDef="let element" class="bolder"> {{element.sdID}} </td>
                    </ng-container>
                    <!-- Weight Column -->
                    <ng-container matColumnDef="dcuActivationStatus">
                      <th mat-header-cell *matHeaderCellDef class="bolder"> Activation </th>
                      <td mat-cell *matCellDef="let element" class="bolder"> {{element.dcuActivationStatus}} </td>
                    </ng-container>
  
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="gisLocation">
                      <th mat-header-cell *matHeaderCellDef class="bolder"> Location </th>
                      <td mat-cell *matCellDef="let element" class="bolder"> {{element.gisLocation}} </td>
                    </ng-container>
  
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="dcuGatewayStrength">
                      <th mat-header-cell *matHeaderCellDef class="bolder"> Gateway Strength </th>
                      <td mat-cell *matCellDef="let element" class="bolder"> {{element.dcuGatewayStrength}} </td>
                    </ng-container>
  
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="ebdgStatusMode">
                      <th mat-header-cell *matHeaderCellDef class="bolder"> Status Mode </th>
                      <td mat-cell *matCellDef="let element" class="bolder"> {{element.ebdgStatusMode}} </td>
                    </ng-container>
  
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="ebStatus">
                      <th mat-header-cell *matHeaderCellDef class="bolder"> EB Status </th>
                      <td mat-cell *matCellDef="let element" class="bolder">
                        <fa name="circle" size="2x"
                          class="{{element.ebStatus=='Y' ? 'dtatusOnColor' : 'dtatusOffColor'}}"></fa>
                      </td>
                    </ng-container>
  
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="dgStatus">
                      <th mat-header-cell *matHeaderCellDef class="bolder"> DG Status </th>
                      <td mat-cell *matCellDef="let element" class="bolder">
                        <fa name="circle" size="2x"
                          class="{{element.dgStatus=='Y' ? 'dtatusOnColor' : 'dtatusOffColor'}}"></fa>
                      </td>
                    </ng-container>
  
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="dcuSIMNo">
                      <th mat-header-cell *matHeaderCellDef class="bolder"> SIM No </th>
                      <td mat-cell *matCellDef="let element" class="bolder"> {{element.dcuSIMNo}} </td>
                    </ng-container>
  
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="dcuRTC">
                      <th mat-header-cell *matHeaderCellDef class="bolder"> Real Date Time </th>
                      <td mat-cell *matCellDef="let element" class="bolder"> {{element.dcuRTC}} </td>
                    </ng-container>
  
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="dcuActiveStatus" class="bolder">
                      <th mat-header-cell *matHeaderCellDef class="bolder"> Active Status </th>
                      <td mat-cell *matCellDef="let element">
                        <fa name="circle" size="2x"
                          class="{{element.dcuActiveStatus=='Y' ? 'dtatusOnColor' : 'dtatusOffColor'}}"></fa>
                      </td>
                    </ng-container>
  
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="dcuActivationDatetime">
                      <th mat-header-cell *matHeaderCellDef class="bolder"> Activation Datetime </th>
                      <td mat-cell *matCellDef="let element" class="bolder"> {{element.dcuActivationDatetime}} </td>
                    </ng-container>
  
                    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                    <ng-container matColumnDef="expandedDetail" col-12>
                      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                        <div class="dcu-meter-list"
                          [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
  
                          <div class="allutilitysloader col-sm-12" *ngIf="!isMeterDataLoad">
                            <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                          </div>
                          <!-- <div class="allutilitysloader col-sm-12" *ngIf="isMeterDataLoad && dataMeterSource.length == 0">
                        No Result Found!
                      </div> -->
                          <table #innerTables mat-table [dataSource]="dataMeterSource"
                            class="mat-elevation-z8 meterTableData" *ngIf="isMeterDataLoad">
  
                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="edit">
                              <th mat-header-cell *matHeaderCellDef>Action</th>
                              <td mat-cell *matCellDef="let row">
                                <button type="button" class="icon npMi" [matMenuTriggerFor]="dcuListMenu">
                                  <mat-icon class="d-c10">menu_open</mat-icon>
                                </button>
                                <mat-menu #dcuListMenu="matMenu" xPosition="before" class="notifications-dropdown">
                                  <mat-list role="list">
                                    <mat-list-item role="listitem">
                                      <button type="button" mat-button (click)="dcuEditView(row, dcumodify)"
                                        class="btn-block text-left">
                                        <mat-icon class="d-c3">edit</mat-icon> Modify
                                      </button>
                                    </mat-list-item>
                                    <mat-list-item role="listitem">
                                      <button type="button" mat-button (click)="dcuRemoveView(row)"
                                        class="btn-block text-left">
                                        <mat-icon class="d-c4">build</mat-icon> Meter Configure
                                      </button>
                                    </mat-list-item>
                                    <mat-list-item role="listitem">
                                      <button type="button" mat-button (click)="dcuRemoveView(row)"
                                        class="btn-block text-left">
                                        <mat-icon class="d-c5">chrome_reader_mode</mat-icon> Actions On Meter
                                      </button>
                                    </mat-list-item>
                                    <mat-list-item role="listitem">
                                      <button type="button" mat-button (click)="dcuRemoveView(row)"
                                        class="btn-block text-left">
                                        <mat-icon class="d-c6">eco</mat-icon> Download From Meter
                                      </button>
                                    </mat-list-item>
                                    <mat-list-item role="listitem">
                                      <button type="button" mat-button (click)="dcuRemoveView(row)"
                                        class="btn-block text-left">
                                        <mat-icon class="d-c7">redeem</mat-icon> Make Recharge
                                      </button>
                                    </mat-list-item>
                                    <mat-list-item role="listitem">
                                      <button type="button" mat-button (click)="dcuRemoveView(row)"
                                        class="btn-block text-left">
                                        <mat-icon class="d-c8">adjust</mat-icon> Make Adjustment
                                      </button>
                                    </mat-list-item>
                                    <mat-list-item role="listitem">
                                      <button type="button" mat-button (click)="dcuRemoveView(row)"
                                        class="btn-block text-left">
                                        <mat-icon class="d-c9">stay_current_portrait</mat-icon> Current Data Report
                                      </button>
                                    </mat-list-item>
                                    <mat-list-item role="listitem">
                                      <button type="button" mat-button (click)="dcuRemoveView(row)"
                                        class="btn-block text-left">
                                        <mat-icon class="d-c10">chrome_reader_mode</mat-icon> Last Bill Report
                                      </button>
                                    </mat-list-item>
                                    <mat-list-item role="listitem">
                                      <button type="button" mat-button (click)="dcuRemoveView(row)"
                                        class="btn-block text-left">
                                        <mat-icon class="d-c11">bug_report</mat-icon> Meter Event Log Report
                                      </button>
                                    </mat-list-item>
                                    <mat-list-item role="listitem">
                                      <button type="button" mat-button (click)="dcuRemoveView(row)"
                                        class="btn-block text-left">
                                        <mat-icon class="d-c12">assignment</mat-icon> Dip Wise Load Survey Report
                                      </button>
                                    </mat-list-item>
                                    <mat-list-item role="listitem">
                                      <button type="button" mat-button (click)="dcuRemoveView(row)"
                                        class="btn-block text-left">
                                        <mat-icon class="d-c13">assignment</mat-icon> Day Wise Load Survey Report
                                      </button>
                                    </mat-list-item>
                                    <mat-list-item role="listitem">
                                      <button type="button" mat-button (click)="dcuRemoveView(row)"
                                        class="btn-block text-left">
                                        <mat-icon class="d-c14">assignment</mat-icon> SMS Sent Report
                                      </button>
                                    </mat-list-item>
                                    <mat-list-item role="listitem">
                                      <button type="button" mat-button (click)="dcuRemoveView(row)"
                                        class="btn-block text-left">
                                        <mat-icon class="d-c15">assignment</mat-icon> LPR Config Data Report
                                      </button>
                                    </mat-list-item>
                                    <mat-list-item role="listitem">
                                      <button type="button" mat-button (click)="dcuRemoveView(row)"
                                        class="btn-block text-left">
                                        <mat-icon class="d-c2">assignment</mat-icon> Meter Activity Report
                                      </button>
                                    </mat-list-item>
                                    <mat-list-item role="listitem">
                                      <button type="button" mat-button (click)="dcuRemoveView(row)"
                                        class="btn-block text-left">
                                        <mat-icon class="d-c3">assignment</mat-icon> Recharge Report
                                      </button>
                                    </mat-list-item>
                                    <mat-list-item role="listitem">
                                      <button type="button" mat-button (click)="dcuRemoveView(row)"
                                        class="btn-block text-left">
                                        <mat-icon class="d-c4">assignment</mat-icon> Consumption Report
                                      </button>
                                    </mat-list-item>
                                    <mat-list-item role="listitem">
                                      <button type="button" mat-button (click)="dcuRemoveView(row)"
                                        class="btn-block text-left">
                                        <mat-icon class="d-c5">delete</mat-icon> Delete
                                      </button>
                                    </mat-list-item>
                                  </mat-list>
                                </mat-menu>
                              </td>
                            </ng-container>
  
                            <ng-container matColumnDef="mID">
                              <th mat-header-cell *matHeaderCellDef> Meter No </th>
                              <td mat-cell *matCellDef="let element">
                                {{element.mID}}
                              </td>
                            </ng-container>
  
                            <ng-container matColumnDef="mlprID">
                              <th mat-header-cell *matHeaderCellDef> LPR ID </th>
                              <td mat-cell *matCellDef="let element">
                                {{element.mlprID}}
                              </td>
                            </ng-container>
  
                            <ng-container matColumnDef="mSimNo">
                              <th mat-header-cell *matHeaderCellDef> Sim No </th>
                              <td mat-cell *matCellDef="let element">
                                {{element.mSimNo}}
                              </td>
                            </ng-container>
  
                            <ng-container matColumnDef="mIMEINo">
                              <th mat-header-cell *matHeaderCellDef> IMEI No </th>
                              <td mat-cell *matCellDef="let element">
                                {{element.mIMEINo}}
                              </td>
                            </ng-container>
  
                            <ng-container matColumnDef="mRTC">
                              <th mat-header-cell *matHeaderCellDef> RTC </th>
                              <td mat-cell *matCellDef="let element">
                                {{element.mRTC}}
                              </td>
                            </ng-container>
  
                            <ng-container matColumnDef="mILA">
                              <th mat-header-cell *matHeaderCellDef> Location </th>
                              <td mat-cell *matCellDef="let element">
                                {{element.mILA}}
                              </td>
                            </ng-container>
  
                            <ng-container matColumnDef="mActiveStatus">
                              <th mat-header-cell *matHeaderCellDef> Active Status </th>
                              <td mat-cell *matCellDef="let element">
                                <fa name="circle" size="2x"
                                  class="{{element.mActiveStatus=='Y' ? 'dtatusOnColor' : 'dtatusOffColor'}}"></fa>
                              </td>
                            </ng-container>
  
                            <ng-container matColumnDef="paginator">
                              <td mat-footer-cell *matFooterCellDef [colSpan]="meterColumnsToDisplay.length">
                                <mat-paginator #meterpaginator [pageSize]="meterlimit" [pageSizeOptions]="meterpageLimit"
                                  [length]="meterTotalRecord" (page)="changeMeterPage($event)"></mat-paginator>
                              </td>
                            </ng-container>
                            <!-- Group1 Column -->
                            <ng-container matColumnDef="group1">
                              <td mat-header-cell *matHeaderCellDef [colSpan]="meterColumnsToDisplay.length">
                                <mat-card-header class="h-16">
                                  <div class="title hidden-sm " col-3>
                                    DCU's Meters List
                                  </div>
                                  <div class="bg-card rounded-full border px-4" col-6>
                                    <mat-form-field class="dcuFilter" floatLabel="never">
                                      <span matSuffix>
                                        <mat-icon matSuffix class="d-c8 fs-30">search</mat-icon>
                                      </span>
                                      <input type="text" matInput placeholder="Search Flat Id Or Meter No"
                                        value="{{dcuMeterSearch}}" (input)="onDcuMeterSearchChange($event.target.value)">
                                    </mat-form-field>
                                  </div>
                                  <span class="toolbar-space"></span>
                                  <div col-3 col-xs-6>
                                    <button type="button" [matMenuTriggerFor]="dcuListMenu" mat-button mat-button-base
                                      mat-primary style="color: #000;">
                                      <mat-icon class="d-c7">filter_list</mat-icon>
                                    </button>
                                    <!--<mat-menu #dcuListMenu="matMenu" xPosition="before" class="notifications-dropdown">
                                      <mat-list role="list" *ngFor="let item of meterHeadsCols">
                                        <mat-list-item role="listitem" (click)="$event.stopPropagation();">
                                          <mat-checkbox [checked]="item.checked"
                                            (change)="updateMeterChange($event, i, item)">{{item.label}}</mat-checkbox>
                                        </mat-list-item>
                                      </mat-list>
                                    </mat-menu>-->
                                    <button type="button" mat-mini-fab class="cPbg npMi" (click)="addDcuMeter()">
                                      <mat-icon class="d-c6">add</mat-icon>
                                    </button>
                                  </div>
                                </mat-card-header>
                              </td>
                            </ng-container>
  
                            <tr mat-header-row *matHeaderRowDef="['group1'];sticky: true"></tr>
                            <tr mat-header-row *matHeaderRowDef="meterColumnsToDisplay" class="tableHeader"></tr>
                            <tr class="tableColumns" mat-row *matRowDef="let element; columns: meterColumnsToDisplay;">
                            </tr>
                            <tr mat-footer-row *matFooterRowDef="['paginator']; sticky: true"></tr>
                          </table>
                        </div>
                      </td>
                    </ng-container>
  
                    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="tableHeader"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                      [class.make-inActive]="row.dcuActiveStatus != 'Y'" class="tableColumns example-element-row"
                      [class.example-expanded-row]="expandedElement === element"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                  </table>
                </mat-card-content>
                <mat-card-footer>
                  <mat-paginator #paginator [pageSize]="limit" [pageSizeOptions]="pageLimit" [length]="dcuTotalRecord"
                    (page)="changePage($event)"></mat-paginator>
                </mat-card-footer>
              </mat-card>
            </div>
          </ng-container>
          <ng-container>
            <div class="">
  
              <div class="boxflexEl">
                <h1>Meter's List (3-PH)
                  <span class="info_icntop ml-1">
                    <i data-toggle="tooltip" data-placement="top" title="All information of meter's list"
                      class="fa fa-info tabula-tooltip"></i>
                  </span>
                </h1>
                <div class="d-none d-lg-block" *ngIf="matHeader!='1'">
                  <div class="innerboxxx col-md-12">
                    <div class="searchflex">
                      <div class="search" *ngIf="getThrough.authority && getThrough.authority.Auth_All_DG_Filter">
                        <select class="search-txt" (ngModelChange)="selectChangeDG($event)" [(ngModel)]="default_DG">
                          <option [value]="0">All DG</option>
                          <option *ngFor="let dg of dgData" [value]="dg.dgId">
                            {{dg.dgName}}
                          </option>
                        </select>
                        <a class="search-btn">
                          <i class="fa fa-search"></i>
                        </a>
                      </div>
                      <div class="search" *ngIf="getThrough.authority && getThrough.authority.Auth_All_Tamper_Filter">
                        <select class="search-txt" (ngModelChange)="selectChangeTower($event)"
                          [(ngModel)]="default_tower">
                          <option [value]="0">All Tower</option>
                          <option *ngFor="let td of towerData" [value]="td.towerId">
                            {{td.towerName}}
                          </option>
                        </select>
                        <a class="search-btn">
                          <i class="fa fa-search"></i>
                        </a>
                      </div>
                      <div class="search" *ngIf="getThrough.authority && getThrough.authority.Auth_All_Group_Filter">
                        <select class="search-txt" (ngModelChange)="selectChangeGroup($event)"
                          [(ngModel)]="default_group">
                          <option [value]="0">All Group</option>
                          <option *ngFor="let td of groupData" [value]="td.groupName">
                            {{td.groupName}}
                          </option>
                        </select>
                        <a class="search-btn">
                          <i class="fa fa-search"></i>
                        </a>
                      </div>
                      <div class="search" *ngIf="getThrough.authority && getThrough.authority.Auth_All_Meter_Filter">
                        <select class="search-txt" (ngModelChange)="selectChange($event)" [(ngModel)]="default_level">
                          <option [value]="0">All Meters</option>
                          <option *ngFor="let td of filterData" [value]="td.id">
                            {{td.name}}
                          </option>
                        </select>
                        <a class="search-btn">
                          <i class="fa fa-search"></i>
                        </a>
                      </div>
                      <div class="search"
                        *ngIf="getThrough.authority && getThrough.authority.Auth_All_Search_Meter_Id_Filter">
                        <input type="text" class="search-txt searchplaceholder" placeholder="Search Flat Id Or Meter No"
                          value="{{meterSearch}}" (input)="onMeterSearchChange($event.target.value)" autocomplete="off">
                        <a class="search-btn">
                          <i class="fa fa-search"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div class="">
                  <div *ngIf="length!=0" class="">
                    <button class="npMi circlebtn" title='Refresh' type="button" (click)="allMeterView(true)">
                      <i class="fa fa-refresh"></i>
                    </button>
                    <button class="npMi circlebtn" title='Select Column' type="button" [matMenuTriggerFor]="dcuListMenu">
                      <i class="fa fa-ellipsis-v" data-toggle="modal" data-target="#gridoption"></i>
                    </button>
                  </div>
                  <mat-menu #dcuListMenu="matMenu" xPosition="before"
                    class="notifications-dropdown modal-contentradius gridinnerpage  listaddinfogrid_bg ">
                    <div class="topspacemodelgrip">
  
                      <button type="button" class="dilog4 close_popupbtn">
                        <mat-icon matPrefix>close</mat-icon>
                      </button>
                      <!-- <div class="refreshflx">
                        <i class="fa fa-refresh"></i>
                      </div> -->
                      <div class="add_rowEgrid">Add Meter Info</div>
                    </div>
                    <mat-list role="list" *ngFor="let item of meterHeadsCols" class="listaddinfogrid">
                      <mat-list-item role="listitem" (click)="$event.stopPropagation();">
                        <mat-checkbox [checked]="item.checked" (change)="updateMeterChange($event, i, item)">
                          {{item.label}}</mat-checkbox>
                      </mat-list-item>
                    </mat-list>
                  </mat-menu>
                  <button type="button" mat-mini-fab class="cPbg npMi" (click)="addMeter()"
                    *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Add_Meter">
                    <i class="fa fa-ellipsis-v"></i>
                  </button>
                </div>
  
              </div>
              <div class="innerboxx col-md-12 ddd">
                <div class="">
  
  
                  <mat-card-header class="h-16 xs-c-h" *ngIf="matHeader=='1'">
  
                    <div class="text hidden-sm xs-dn col-md-8">
                      <a href="#/deshauth" mat-icon>
                        <i matprefix class="icons d-c3">
                          home
                        </i><span>Home</span>
                      </a>->
                      <a href="javascript:void(0)" (click)="backToStateView()">
                        <i matprefix class="icons d-c4">
                          flag
                        </i><span>{{bCumCountry}}</span>
                      </a>->
                      <a href="javascript:void(0)" (click)="backToUtilityView()">
                        <i matprefix class="icons d-c5">
                          flag
                        </i><span>{{bCumState}}</span>
                      </a>->
                      <a href="javascript:void(0)" (click)="backToCircleView()">
                        <i matprefix class="icons d-c6">
                          flag
                        </i><span>{{bCumUtlity}}</span>
                      </a>->
                      <a href="javascript:void(0)" (click)="backToDivisionView()">
                        <i matprefix class="icons d-c7">
                          flag
                        </i><span>{{bCumCircle}}</span>
                      </a>->
  
                      <a href="javascript:void(0)" (click)="backToSubDivisionView()">
                        <i matprefix class="icons d-c8">
                          flag
                        </i><span>{{bCumDivision}}</span>
                      </a>
  
                      <a href="javascript:void(0)">
                        <i matprefix class="icons d-c8">
                          flag
                        </i><span>{{bCumSubDivision}}</span>
                      </a>
  
                    </div>
                    <div class="bg-card rounded-full border col-md-2">
                      <mat-form-field class="dcuFilter" floatLabel="never">
                        <span matSuffix>
                          <mat-icon matSuffix class="d-c9">search</mat-icon>
                        </span>
                        <input type="text" matInput placeholder="Search Flat Id Or Meter No's" value="{{meterSearch}}"
                          (input)="onMeterSearchChange($event.target.value)" autocomplete="off">
                      </mat-form-field>
                    </div>
  
                    <div class="col-md-2">
                      <button type="button" [matMenuTriggerFor]="dcuListMenu" mat-button mat-button-base mat-primary
                        style="color: #000;" class="npMi">
                        <mat-icon class="d-c10">filter_list</mat-icon>
                      </button>
                      <mat-menu #dcuListMenu="matMenu" xPosition="before" class="notifications-dropdown">
                        <mat-list role="list" *ngFor="let item of meterHeadsCols">
                          <mat-list-item role="listitem" (click)="$event.stopPropagation();">
                            <mat-checkbox [checked]="item.checked" (change)="updateMeterChange($event, i, item)">
                              {{item.label}}</mat-checkbox>
                          </mat-list-item>
                        </mat-list>
                      </mat-menu>
                      <button type="button" mat-mini-fab class="cPbg npMi" (click)="addMeter()"
                        *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Add_Meter">
                        <mat-icon class="d-c11">add</mat-icon>
                      </button>
                      <!--<button type="button" mat-mini-fab (click)="open(addcountry)">
                          <mat-icon>add</mat-icon>
                      </button>-->
                      <!--<button mat-flat-button  (click)="whichSubDomain()" class="cPbg" *ngIf="enableSocietyChange">
                          <mat-icon class="d-c11">reply</mat-icon>
                      </button>-->
                    </div>
  
                  </mat-card-header>
  
                  <div class="container-fluidd d-block  d-lg-none">
                    <div class="">
                      <div class="outerbox_shadoww grid_searchsm">
                        <div id="accordion" class="accordion">
                          <div class="card mb-1">
                            <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseThree">
                              <a class="card-title">
                                <span class="headngins">Search</span>
                              </a>
                            </div>
                            <div id="collapseThree" class="collapse" data-parent="#accordion">
                              <div class="card-bodyy">
                                <div class="innerboxx">
                                  <div class="" *ngIf="matHeader!='1'">
                                    <div class="searchflex">
                                      <div class="search"
                                        *ngIf="getThrough.authority && getThrough.authority.Auth_All_DG_Filter">
                                        <select class="search-txt" (selectionChange)="selectChangeDG(default_DG)"
                                          [(ngModel)]="default_DG">
                                          <option [value]="0">All DG</option>
                                          <option *ngFor="let dg of dgData" [value]="dg.dgId">
                                            {{dg.dgName}}
                                          </option>
                                        </select>
                                        <a class="search-btn" href="#">
                                          <i class="fa fa-search"></i>
                                        </a>
                                      </div>
                                      <div class="search"
                                        *ngIf="getThrough.authority && getThrough.authority.Auth_All_Tamper_Filter">
                                        <select class="search-txt" (selectionChange)="selectChangeTower(default_tower)"
                                          [(ngModel)]="default_tower">
                                          <option [value]="0">All Tower</option>
                                          <option *ngFor="let td of towerData" [value]="td.towerId">
                                            {{td.towerName}}
                                          </option>
                                        </select>
                                        <a class="search-btn" href="#">
                                          <i class="fa fa-search"></i>
                                        </a>
                                      </div>
                                      <div class="search"
                                        *ngIf="getThrough.authority && getThrough.authority.Auth_All_Group_Filter">
                                        <select class="search-txt" (selectionChange)="selectChangeGroup(default_group)"
                                          [(ngModel)]="default_group">
                                          <option [value]="0">All Group</option>
                                          <option *ngFor="let td of groupData" [value]="td.groupName">
                                            {{td.groupName}}
                                          </option>
                                        </select>
                                        <a class="search-btn" href="#">
                                          <i class="fa fa-search"></i>
                                        </a>
                                      </div>
                                      <div class="search"
                                        *ngIf="getThrough.authority && getThrough.authority.Auth_All_Meter_Filter">
                                        <select class="search-txt" (selectionChange)="selectChange(default_level)"
                                          [(ngModel)]="default_level">
                                          <option [value]="0">All Meters</option>
                                          <option *ngFor="let td of filterData" [value]="td.id">
                                            {{td.name}}
                                          </option>
                                        </select>
                                        <a class="search-btn" href="#">
                                          <i class="fa fa-search"></i>
                                        </a>
                                      </div>
                                      <div class="search"
                                        *ngIf="getThrough.authority && getThrough.authority.Auth_All_Search_Meter_Id_Filter">
                                        <input type="text" class="search-txt searchplaceholder"
                                          placeholder="Search Flat Id Or Meter No" value="{{meterSearch}}"
                                          (input)="onMeterSearchChange($event.target.value)" autocomplete="off">
                                        <a class="search-btn" href="#">
                                          <i class="fa fa-search"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <mat-card-content>
                    <ng-container *ngIf="loadingFirstTime">
                      <div class="col-sm-12 text-center">
                        <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                      </div>
                    </ng-container>
                    <div *ngIf="length!=0" class="roww col-md-12">
  
                      <table id="ExampleMaterialTable" mat-table [dataSource]="dataAllMeterSource"
                        class="mat-elevation-z8 22" matSort>
  
                        <ng-container class="allutilitysloader col-sm-12" *ngIf="!isDataRedyForTableDcu">
                          <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                        </ng-container>
                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="edit">
                          <th class="w35tbl" mat-header-cell *matHeaderCellDef>Action</th>
                          <td mat-cell *matCellDef="let row">
                            <button type="button" class="icon iconmenuheight" [matMenuTriggerFor]="dcuListMenu">
                              <mat-icon class="iconaction">menu_open</mat-icon>
                            </button>
  
                            <mat-menu #dcuListMenu="matMenu" xPosition="before"
                              class="notifications-dropdown modal-contentradius gridinnerpage listactionspcc">
                              <div class="topspacemodelgrip">
                                <button type="button" class="dilog4 close_popupbtn">
                                  <mat-icon matPrefix>close</mat-icon>
                                </button>
                                <div class="refreshflx">
                                  <i class="fa fa-refresh"></i>
                                </div>
  
                                <div class="add_rowEgrid">Action</div>
                              </div>
                              <mat-list role="list" class="cstmborderall">
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Modify_Meter">
                                  <button type="button" mat-button (click)="editMeter(row)" class="btn-block text-left">
                                    <mat-icon class="d-c13">edit</mat-icon> Modify
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Recharge_Button">
                                  <button type="button" mat-button (click)="meterRecharge(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c14">trending_up</mat-icon> Make Recharge
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Adjustment_Button">
                                  <button type="button" mat-button (click)="meterAdjustment(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c15">trending_down</mat-icon> Make Adjustment
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority.Meter_Sub_Configure_Meter_Button">
                                  <button type="button" mat-button (click)="meterConfig(row)" class="btn-block text-left">
                                    <mat-icon class="d-c2">build</mat-icon> Configure Meter
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Meter_Config">
                                  <button type="button" mat-button (click)="meterConfigUtility(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c2">build</mat-icon> Configure Meter
                                  </button>
                                </mat-list-item>
                                <!--<mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Meter_Config">
                                  <button type="button" mat-button (click)="meterConfigUtilityNew(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c2">build</mat-icon> Configure Meter 1
                                  </button>
                                </mat-list-item>-->
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Meter_Teriff">
                                  <button type="button" mat-button (click)="meterConfigTeriffUtility(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c2">build</mat-icon> Configure Meter Teriff
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Activity_On_Meter_Button">
                                  <button type="button" mat-button (click)="meterActivity(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c3">chrome_reader_mode</mat-icon> Activity On Meter
                                  </button>
                                </mat-list-item>
  
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Download_Option_Button">
                                  <button type="button" mat-button (click)="meterReporting(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c4">eco</mat-icon>Download Meter
                                  </button>
                                </mat-list-item>
  
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Real_Time_Data_Button">
                                  <button type="button" mat-button (click)="authRealTimeData(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c5">assignment</mat-icon>Meter Real Time data
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Recent_Inst_Data_Button">
                                  <button type="button" mat-button (click)="recentInstData(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c7">assignment</mat-icon> Instantaneous data Log
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Recent_Recharge_History_Button">
                                  <button type="button" mat-button (click)="recentRecharge(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c7">assignment</mat-icon> Recent Recharge History
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Recent_Adjustment_History_Button">
                                  <button type="button" mat-button (click)="recentAdjustment(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c8">assignment</mat-icon> Recent Adjustment History
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Recent_Bill_Button">
                                  <button type="button" mat-button (click)="recentBill(row)" class="btn-block text-left">
                                    <mat-icon class="d-c10">assignment</mat-icon>Recent Bill
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Recent_Bills_Button">
                                  <button type="button" mat-button (click)="recentBills(row)" class="btn-block text-left">
                                    <mat-icon class="d-c10">assignment</mat-icon>Recent Bills
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Recent_Bills_Data_Button">
                                  <button type="button" mat-button (click)="recentBillData(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c10">assignment</mat-icon>Billing Data Log
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Recent_Events_Button">
                                  <button type="button" mat-button (click)="recentEvent(row)" class="btn-block text-left">
                                    <mat-icon class="d-c11">assignment</mat-icon>Recent Events
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Recent_Other_Events_Button">
                                  <button type="button" mat-button (click)="recentOtherEvent(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c11">assignment</mat-icon>Other Events Log
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Recent_Voltage_Events_Button">
                                  <button type="button" mat-button (click)="recentVoltageEvent(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c11">assignment</mat-icon>Voltage Events Log
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Recent_Current_Events_Button">
                                  <button type="button" mat-button (click)="recentCurrentEvent(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c11">assignment</mat-icon>Current Events Log
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Recent_Non_Roll_Events_Button">
                                  <button type="button" mat-button (click)="recentNonRollEvent(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c11">assignment</mat-icon>Non-Roll Over Events Log
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Recent_Control_Events_Button">
                                  <button type="button" mat-button (click)="recentControlEvent(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c11">assignment</mat-icon>Control Events Log
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Recent_Power_Events_Button">
                                <button type="button" mat-button (click)="recentPowerEvent(row)"
                                  class="btn-block text-left">
                                  <mat-icon class="d-c11">assignment</mat-icon>Power Events Log
                                </button>
                              </mat-list-item>
                              <mat-list-item role="listitem" class="cstmbordergrid"
                                *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Recent_Transaction_Events_Button">
                                <button type="button" mat-button (click)="recentTransactionEvent(row)"
                                  class="btn-block text-left">
                                  <mat-icon class="d-c11">assignment</mat-icon>Transaction Events Log
                                </button>
                              </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_OnGoing_Consumption_Button">
                                  <button type="button" mat-button (click)="recentDipWiseLoadSurvey(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c12">assignment</mat-icon>Ongoing Consumption
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_OnGoing_Consumption_New_Button">
                                  <button type="button" mat-button (click)="recentOnGoingConsumption(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c12">assignment</mat-icon>Ongoing Consumption
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_OnGoing_Consumption_New_Button_Pvt">
                                <button type="button" mat-button (click)="recentOnGoingConsumptionPvt(row)"
                                  class="btn-block text-left">
                                  <mat-icon class="d-c12">assignment</mat-icon>Ongoing Consumption Log
                                </button>
                              </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Day_Consumptiion_Button">
                                  <button type="button" mat-button (click)="recentDayConsumption(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c13">assignment</mat-icon>Daily Consumption
                                  </button>
                                </mat-list-item>
                               
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Daily_Consumptiion_Button">
                                  <button type="button" mat-button (click)="recentDayLoadSurvey(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c13">assignment</mat-icon>Daily Consumption
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Daily_Consumptiion_Button_Pvt">
                                  <button type="button" mat-button (click)="recentDayLoadSurveyPvt(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c13">assignment</mat-icon>Daily Consumption Log
                                  </button>
                                </mat-list-item>
  
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Inst_Push_Button">
                                  <button type="button" mat-button (click)="recentInstPush(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c13">assignment</mat-icon>Inst Push Log
                                  </button>
                                </mat-list-item>
  
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Name_Plate_Button">
                                  <button type="button" mat-button (click)="recentNamePlate(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c13">assignment</mat-icon>Name Plate Log
                                  </button>
                                </mat-list-item>

                                <!--<mat-list-item role="listitem" class="cstmbordergrid"
                                *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Name_Plate_Button">
                                <button type="button" mat-button (click)="recentPushLog(row)"
                                  class="btn-block text-left">
                                  <mat-icon class="d-c13">assignment</mat-icon>Event Push Log
                                </button>
                              </mat-list-item>-->
  
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Recent_Activity_Button">
                                  <button type="button" mat-button (click)="recentActivity(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c14">assignment</mat-icon>Recent Activity
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Recent_SMS_Sent_Button">
                                  <button type="button" mat-button (click)="recentSmsSent(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c15">assignment</mat-icon> Recent SMS Sent
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Billing_Teriff_Status_Button">
                                  <button type="button" mat-button (click)="teriff(row)" class="btn-block text-left">
                                    <mat-icon class="d-c3">assignment</mat-icon>Recent Meter Tariff
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Load_Limit_Config_Status_Button">
                                  <button type="button" mat-button (click)="loadLimit(row)" class="btn-block text-left">
                                    <mat-icon class="d-c4">assignment</mat-icon>Recent Meter Loadlimits
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Happy_Hours_Config_Status_Button">
                                  <button type="button" mat-button (click)="happyHours(row)" class="btn-block text-left">
                                    <mat-icon class="d-c5">assignment</mat-icon>Recent Happy Hours Config
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Billing_Scheduler_Status_Button">
                                  <button type="button" mat-button (click)="billingSchedler(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c6">assignment</mat-icon>Recent Billing Scheduler Config
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Billing_Scheduler_Status_Button">
                                  <button type="button" mat-button (click)="relay(row)" class="btn-block text-left">
                                    <mat-icon class="d-c9">assignment</mat-icon>Recent Relay Config
                                  </button>
                                </mat-list-item>
  
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Billing_Teriff_From_Meter_Button">
                                  <button type="button" mat-button (click)="teriffMeter(row)" class="btn-block text-left">
                                    <mat-icon class="d-c3">assignment</mat-icon>Billing Tariff From Meter
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Load_Limit_Config_From_Meter_Button">
                                  <button type="button" mat-button (click)="loadLimitMeter(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c4">assignment</mat-icon>Load limits Config From Meter
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Happy_Hours_Config_From_Meter_Button">
                                  <button type="button" mat-button (click)="happyHoursMeter(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c5">assignment</mat-icon>Happy Hours Config From Meter
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Billing_Scheduler_From_Meter_Button">
                                  <button type="button" mat-button (click)="billingSchedlerMeter(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c6">assignment</mat-icon>Billing Scheduler From Meter
                                  </button>
                                </mat-list-item>
  
                                <mat-list-item role="listitem" class="cstmbordergrid"
                                  *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Recent_Complaints_Button">
                                  <button type="button" mat-button (click)="latestComplaint(row)"
                                    class="btn-block text-left">
                                    <mat-icon class="d-c7">assignment</mat-icon>Recent Complaints
                                  </button>
                                </mat-list-item>
                              </mat-list>
                            </mat-menu>
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="srNo">
                          <th class="w35tbll" mat-header-cell *matHeaderCellDef mat-sort-header>Sr. No</th>
                          <!--<td mat-cell *matCellDef="let element; let j = index;" style="text-align: Middle;"> 
                        {{ (j+1) + (meterpaginator.pageIndex * meterpaginator.pageSize) }} 
                      </td>-->
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.srNo}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="cACNO"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Cons_UId_Column">
                          <th title="Sort By Cons UID" mat-header-cell *matHeaderCellDef mat-sort-header> Cons. UId </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.cACNO}}</div>
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="dgName"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Cons_DG_Column">
                          <th title="Sort By DG Id" mat-header-cell *matHeaderCellDef mat-sort-header> DG Id </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;"><B>{{element.dgName}}</B></div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="towerName"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Cons_Tower_Column">
                          <th title="Sort By Tower Id" mat-header-cell *matHeaderCellDef mat-sort-header> Tower Id </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;"><b>{{element.towerName}}</b></div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="groupName"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Cons_Group_Column">
                          <th title="Sort By Group ID" mat-header-cell *matHeaderCellDef mat-sort-header> Group Id </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;"><B>{{element.groupName}}</B></div>
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="muID"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Meter_UId_Column">
                          <th title="Sort By Meter UID" mat-header-cell *matHeaderCellDef mat-sort-header> Meter UId </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.muID}}</div>
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="flatNo"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Flat_Id_Column">
                          <th title="Sort By Flat No" mat-header-cell *matHeaderCellDef mat-sort-header> Flat No </th>
                          <td mat-cell *matCellDef="let element" style="text-align: left;padding:5px">
                            <div style="padding-right: 8px;"><b>{{element.flatNo}}</b></div>
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="mID"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Meter_No_Column">
                          <th title="Sort By Meter No" mat-header-cell *matHeaderCellDef mat-sort-header> Meter No </th>
                          <td mat-cell *matCellDef="let element" style="padding:5px">
                            <div style="padding-right: 8px;"><b>{{element.mID}}</b></div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="mType"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Meter_Type_Column">
                          <th title="Sort By Meter Type" mat-header-cell *matHeaderCellDef mat-sort-header> Meter Type
                          </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.mType}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="hesId"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Hes_Id_Column">
                          <th title="Sort By HES ID" mat-header-cell *matHeaderCellDef mat-sort-header> HES Id</th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.hesId}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="mIPAdd"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Meter_Ip_Column">
                          <th title="Sort By IP Address" mat-header-cell *matHeaderCellDef mat-sort-header> IP Address
                          </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.mIPAdd}}</div>
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="mLLEBSupply"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_LL_Grid_kW_Column">
                          <th title="Sort By LL Grid (kW)" mat-header-cell *matHeaderCellDef mat-sort-header>LL Grid(kW)
                          </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.mLLEBSupply}}</div>
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="mLLDGSupply"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_LL_DG_kW_Column">
                          <th title="Sort By LL DG (kW)" mat-header-cell *matHeaderCellDef mat-sort-header>LL DG(kW)</th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.mLLDGSupply}}</div>
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="mRTC"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Last_Update_Column">
                          <th title="Sort By Last Update" mat-header-cell *matHeaderCellDef mat-sort-header> Last Update
                          </th>
                          <td mat-cell *matCellDef="let element" style="padding:5px">
                            <div style="padding-right: 8px;">{{element.mRTC}}</div>
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="voltR"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_Voltage_R_Column">
                          <th title="Sort By Voltage-R" mat-header-cell *matHeaderCellDef mat-sort-header> Voltage-R </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.voltR}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="voltY"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_Voltage_Y_Column">
                          <th title="Sort By Voltage-Y" mat-header-cell *matHeaderCellDef mat-sort-header> Voltage-Y </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.voltY}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="voltB"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_Voltage_B_Column">
                          <th title="Sort By Voltage-B" mat-header-cell *matHeaderCellDef mat-sort-header> Voltage-B</th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.voltB}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="phaseCurrentR"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_Phase_Current_R_Column">
                          <th title="Sort By Phase Currren-R" mat-header-cell *matHeaderCellDef mat-sort-header> Phase
                            Currrent-R </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.phaseCurrentR}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="phaseCurrentY"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_Phase_Current_Y_Column">
                          <th title="Sort By Phase Currren-Y" mat-header-cell *matHeaderCellDef mat-sort-header> Phase
                            Currrent-Y </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.phaseCurrentY}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="phaseCurrentB"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_Phase_Current_B_Column">
                          <th title="Sort By Phase Currren-B" mat-header-cell *matHeaderCellDef mat-sort-header> Phase
                            Current-B </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.phaseCurrentB}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="ncR"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_Nutural_Current_R_Column">
                          <th title="Sort By Neutral Currrent" mat-header-cell *matHeaderCellDef mat-sort-header> Neutral
                            Current </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.ncR}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="ncY"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_Nutural_Current_Y_Column">
                          <th title="Sort By Neutral Currrent-Y" mat-header-cell *matHeaderCellDef mat-sort-header>
                            Neutral Current-Y</th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.ncY}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="ncB"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_Nutural_Current_B_Column">
                          <th title="Sort By Neutral Currrent-B" mat-header-cell *matHeaderCellDef mat-sort-header>
                            Neutral Current-B </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.ncB}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="pfR"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_PF_R_Column">
                          <th title="Sort By Neutral PF-R" mat-header-cell *matHeaderCellDef mat-sort-header> PF-R </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.pfR}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="pfY"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_PF_Y_Column">
                          <th title="Sort By Neutral PF-Y" mat-header-cell *matHeaderCellDef mat-sort-header> PF-Y </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.pfY}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="pfB"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_PF_B_Column">
                          <th title="Sort By Neutral PF-B" mat-header-cell *matHeaderCellDef mat-sort-header> PF-B </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.pfB}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="totalPF"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_Total_Power_Factor_Column">
                          <th title="Sort By Total PF" mat-header-cell *matHeaderCellDef mat-sort-header>Total PF</th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.totalPF}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="frequency"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_Frequency_Column">
                          <th title="Sort By Frequency" mat-header-cell *matHeaderCellDef mat-sort-header> Frequency</th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.frequency}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="kWHPower"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_Active_Power_Column">
                          <th title="Sort By kW Power" mat-header-cell *matHeaderCellDef mat-sort-header> kW Power </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.kWHPower}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="kvarhPower"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_Reactive_Power_Column">
                          <th title="Sort By kVAR Power" mat-header-cell *matHeaderCellDef mat-sort-header> kVAR Power
                          </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.kvarhPower}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="kvahPower"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_ApperantPower_kVA_Column">
                          <th title="Sort By kVA Power" mat-header-cell *matHeaderCellDef mat-sort-header> kVA Power </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;"> {{element.kvahPower}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="mdKW"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_Md_Kw_Column">
                          <th title="Sort By MD kW" mat-header-cell *matHeaderCellDef mat-sort-header>MD kW</th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;"> {{element.mdKW}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="mdKva"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_Md_kVA_Column">
                          <th title="Sort By MD kVA" mat-header-cell *matHeaderCellDef mat-sort-header>MD kVA</th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.mdKva}}</div>
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="activeEnergyEB"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_kWH_Column">
                          <th title="Sort By Grid kWh" mat-header-cell *matHeaderCellDef mat-sort-header> Grid kWh </th>
                          <td mat-cell *matCellDef="let element" style="text-align: right;">
                            <div style="padding-right: 8px;">{{element.activeEnergyEB}}</div>
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="reactiveEnergyEBQ1"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_kVARH_Q1_Column">
                          <th title="Sort By Grid kVArh Q1" mat-header-cell *matHeaderCellDef mat-sort-header> Grid kVArh
                            Q1</th>
                          <td mat-cell *matCellDef="let element" style="text-align: right;">
                            <div style="padding-right: 8px;">{{element.reactiveEnergyEBQ1}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="reactiveEnergyEBQ2"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_kVARH_Q2_Column">
                          <th title="Sort By Grid kVArh Q2" mat-header-cell *matHeaderCellDef mat-sort-header> Grid kVArh
                            Q2</th>
                          <td mat-cell *matCellDef="let element" style="text-align: right;">
                            <div style="padding-right: 8px;">{{element.reactiveEnergyEBQ2}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="reactiveEnergyEBQ3"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_kVARH_Q3_Column">
                          <th title="Sort By Grid kVArh Q3" mat-header-cell *matHeaderCellDef mat-sort-header> Grid kVArh
                            Q3</th>
                          <td mat-cell *matCellDef="let element" style="text-align: right;">
                            <div style="padding-right: 8px;">{{element.reactiveEnergyEBQ3}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="reactiveEnergyEBQ4"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_kVARH_Q4_Column">
                          <th title="Sort By Grid kVArh Q4" mat-header-cell *matHeaderCellDef mat-sort-header> Grid kVArh
                            Q4</th>
                          <td mat-cell *matCellDef="let element" style="text-align: right;">
                            <div style="padding-right: 8px;">{{element.reactiveEnergyEBQ4}}</div>
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="apperantEnergyEB"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Grid_kVAH_Column">
                          <th title="Sort By Grid kVAh" mat-header-cell *matHeaderCellDef mat-sort-header> Grid kVAh </th>
                          <td mat-cell *matCellDef="let element" style="text-align: right;">
                            <div style="padding-right: 8px;">{{element.apperantEnergyEB}}</div>
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="activeEnergyDG"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_DG_kWH_Column">
                          <th title="Sort By DG kWh" mat-header-cell *matHeaderCellDef mat-sort-header> DG kWh</th>
                          <td mat-cell *matCellDef="let element" style="text-align: right;">
                            <div style="padding-right: 8px;">{{element.activeEnergyDG}}</div>
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="apperantEnergyDG"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_DG_kVAH_Column">
                          <th title="Sort By DG kWh" mat-header-cell *matHeaderCellDef mat-sort-header> DG kVAh</th>
                          <td mat-cell *matCellDef="let element" style="text-align: right;">
                            <div style="padding-right: 8px;">{{element.apperantEnergyDG}}</div>
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="mlprID"
                          *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Add_Meter">
                          <th title="Sort By LPR Id" mat-header-cell *matHeaderCellDef mat-sort-header> LPR ID </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.mlprID}}</div>
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="availableAmount"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Balance_Column">
                          <th title="Sort By Balance" mat-header-cell *matHeaderCellDef mat-sort-header>Balance</th>
  
  
                          <td mat-cell *matCellDef="let element" style="text-align: right;">
                            <div class="led-box" *ngIf="element.availableAmount=='I'">
                              <div>---</div>
                            </div>
                            <div class="led-box"
                              *ngIf="(parseFloat(element.availableAmount) > 0) && (parseFloat(element.availableAmount) <= parseFloat(element.lowBalance))">
                              <div class="icn_dg led-yellow">
                                {{element.availableAmount}}</div>
                            </div>
                            <div class="led-box" *ngIf="(parseFloat(element.availableAmount) <=0)">
                              <div class="icn_dg led-red">
                                {{element.availableAmount}}</div>
                            </div>
                            <div class="led-box"
                              *ngIf="(parseFloat(element.availableAmount) > parseFloat(element.lowBalance))">
                              <div class="icn_dg led-green">
                                {{element.availableAmount}}</div>
                            </div>
                          </td>
  
                        </ng-container>
                        <!--<ng-container matColumnDef="currentBalElect"
                        *ngIf="getThrough.authority && getThrough.authority.Auth_All_Balance_Column">
                        <th title="Sort By Sim No" mat-header-cell *matHeaderCellDef mat-sort-header>  Total Maint. Rechar5ge. </th>
                        <td mat-cell *matCellDef="let element">
                          <div style="padding-right: 8px;">{{element.currentBalElect}}</div>
                        </td>
                      </ng-container>-->
  
                        <ng-container matColumnDef="currentBalMaint"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Balance_Column">
                          <th title="Sort By Balance" mat-header-cell *matHeaderCellDef mat-sort-header>Current Bal Maint
                          </th>
  
  
                          <td mat-cell *matCellDef="let element" style="text-align: right;">
                            <div class="led-box" *ngIf="element.currentBalMaint=='I'">
                              <div>---</div>
                            </div>
  
                            <div class="led-box" *ngIf="(parseFloat(element.currentBalMaint) <=0)">
                              <div class="icn_dg led-red">
                                {{element.currentBalMaint}}</div>
                            </div>
                            <div class="led-box" *ngIf="(parseFloat(element.currentBalMaint) >=0)">
                              <div class="icn_dg led-green">
                                {{element.currentBalMaint}}</div>
                            </div>
                          </td>
  
                        </ng-container>
  
  
  
  
  
  
                        <ng-container matColumnDef="mSimNo"
                          *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Add_Meter">
                          <th title="Sort By Sim No" mat-header-cell *matHeaderCellDef mat-sort-header> Sim No </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.mSimNo}}</div>
                          </td>
                        </ng-container>
  
  
                        <ng-container matColumnDef="mIMEINo"
                          *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Add_Meter">
                          <th title="Sort By IMEI No" mat-header-cell *matHeaderCellDef mat-sort-header> IMEI No </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.mIMEINo}}</div>
                          </td>
                        </ng-container>
  
  
  
                        <ng-container matColumnDef="mILA"
                          *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Add_Meter">
                          <th title="Sort By Location" mat-header-cell *matHeaderCellDef mat-sort-header> Location </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.mILA}}</div>
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="recentLsDateTime"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Last_LS_DT_Column">
                          <th title="Sort By Last LS D/T" mat-header-cell *matHeaderCellDef mat-sort-header>Last LS D/T
                          </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.recentLsDateTime}}</div>
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="recentBlockLsDateTime"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Last_Block_LS_DT_Column">
                          <th title="Sort By Last Block LS D/T" mat-header-cell *matHeaderCellDef mat-sort-header>Last
                            Block LS D/T </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.recentBlockLsDateTime}}</div>
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="tamperCount"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Tamper_Count_Column">
                          <th title="Sort By Tamper Count" mat-header-cell *matHeaderCellDef mat-sort-header>Tamper Count
                          </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.tamperCount}}</div>
                          </td>
                        </ng-container>
  
  
  
                        <ng-container matColumnDef="billingCount"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Billng_Count_Column">
                          <th title="Sort By Billing Count" mat-header-cell *matHeaderCellDef mat-sort-header>Billing
                            Count</th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.billingCount}}</div>
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="antennaPos"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Antenna_Column">
                          <th title="Sort By Antenna Position" mat-header-cell *matHeaderCellDef mat-sort-header>Antenna
                            Position</th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.antennaPos}}</div>
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="gprsSimType"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Sim_Type_Column">
                          <th title="Sort By Sim Provider" mat-header-cell *matHeaderCellDef mat-sort-header>Sim Provider
                          </th>
                          <td mat-cell *matCellDef="let element">
                            <div style="padding-right: 8px;">{{element.gprsSimType}}</div>
                          </td>
                        </ng-container>
  
                        <!--<ng-container matColumnDef="lowBalance">
                              <th mat-header-cell *matHeaderCellDef> Low Balance </th>
                              <td width="10%" mat-cell *matCellDef="let element">
                                <fa name="circle" size="2x"
                                  class="{{ element.availableAmount <= element.lowBalance ? 'dtatusOnColor' : 'dtatusOffColor'}}"></fa>
                              </td>
                            </ng-container>-->
  
                        <!--<ng-container matColumnDef="currentRunningMode">
                              <th mat-header-cell *matHeaderCellDef> DG </th>
                              <td width="10%" mat-cell *matCellDef="let element" >
                                <fa name="circle" size="2x"
                                  class="{{element.currentRunningMode=='E' ? 'dtatusOffColor' : 'dtatusOnColor'}}"></fa>
                              </td>
                            </ng-container>-->
  
                        <ng-container matColumnDef="currentRunningMode"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Supply_Column">
                          <th class="w35tbl" title="Sort By Supply" mat-header-cell *matHeaderCellDef mat-sort-header>
                            Supply </th>
                          <td mat-cell *matCellDef="let element">
                            <div class="led-box" *ngIf="element.currentRunningMode=='I'">
                              <div>---</div>
                            </div>
                            <div class="led-box" *ngIf="element.currentRunningMode=='E'">
                              <div class="toggle-switch-wrapper">
                                <label>
                                  <span class="active">
                                    <i class="faNew fa-check-circle" aria-hidden="true"></i>
                                    EB Supply
                                  </span>
                                </label>
                                <!--<img class="blink-image" src="assets/images/gridsupply.png" style="width: 18px;">-->
                              </div>
                            </div>
                            <div class="led-box" *ngIf="element.currentRunningMode=='D'">
                              <div class="toggle-switch-wrapper">
                                <label>
                                  <span class="red">
                                    <i class="faNew fa-check-circle" aria-hidden="true"></i>
                                    DG Supply
                                  </span>
                                </label>
                              </div>
                            </div>
                            <!--<img class="blink-image" src="assets/images/generac.png" style="width: 18px;">-->
  
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="tamperStatus"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Tamper_Column">
                          <th title="Sort By Tamper" mat-header-cell *matHeaderCellDef mat-sort-header>Tamper</th>
                          <td mat-cell *matCellDef="let element">
                            <div class="led-box" *ngIf="element.tamperStatus=='0' || element.tamperStatus==''">
                              <!--<div class="led-white"></div>-->
                              ---
                            </div>
                            <!--<div class="led-box" *ngIf="element.tamperStatus!='0'">
                            <div class="led-red"></div>
                          </div>-->
  
                            <div class="" *ngIf="element.tamperStatus!='0'">
                              <div>
                                <img class="blink-image" src="assets/images/tamper.png" style="width: 18px;">
                              </div>
                            </div>
                          </td>
                        </ng-container>
  
  
                        <ng-container matColumnDef="lowBalance"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Balance_Column">
                          <th title="Sort By Balance" mat-header-cell *matHeaderCellDef mat-sort-header> Balance </th>
                          <td mat-cell *matCellDef="let element">
                            <div class="led-box" *ngIf="(parseFloat(element.availableAmount) <=0)">
                              <div class="icn_dg led-red">
                                {{element.availableAmount}}</div>
                            </div>
                            <div class="led-box"
                              *ngIf="(parseFloat(element.availableAmount) > 0) && (parseFloat(element.availableAmount) <= parseFloat(element.lowBalance))">
                              <div class="icn_dg led-yellow">
                                {{element.availableAmount}}</div>
                            </div>
  
                            <div class="led-box"
                              *ngIf="(parseFloat(element.availableAmount) > parseFloat(element.lowBalance))">
                              <div class="icn_dg led-green">
                                {{element.availableAmount}}</div>
                            </div>
                          </td>
                        </ng-container>
  
  
                        <!--<ng-container matColumnDef="currentRunningMode">
                            <th mat-header-cell *matHeaderCellDef> EB/DG </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="led-box" *ngIf="element.currentRunningMode=='E'">
                                  <div class="led-green"></div>
                                  
                                </div>
                                <div class="led-box" *ngIf="element.currentRunningMode!='E'">
                                  <div class="led-red"></div>
                                  
                                </div>
                            </td>
                          </ng-container>-->
  
  
                        <ng-container matColumnDef="backupStatus"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_DG_Supply_Status_Column">
                          <th title="Sort By Backup Status" mat-header-cell *matHeaderCellDef mat-sort-header>Backup
                            Status</th>
                          <td mat-cell *matCellDef="let element">
  
                            <div *ngIf="element.backupStatus=='Active'">
                              <!--<div class="toggle-switch-wrapper">
                                <label><span class="active"><i class="faNew fa-check-circle-o"
                                      aria-hidden="true"></i>Active</span></label>
                              </div>-->
                              <div class="toggle-switch-wrapper">
                                <label><span class="active">
                                    <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                                    Active</span></label>
                              </div>
                            </div>
                            <div *ngIf="element.backupStatus!='Active'">
                              <div class="toggle-switch-wrapper"><label><span class="inactive"><i
                                      class="faNew fa-times-circle-o" aria-hidden="true"></i>In-Active</span></label>
                              </div>
                              <!--<div class="toggle-switch-wrapper">
                                <label><span class="active">
                                    <i class="fa fa-plug" aria-hidden="true"></i>
                                    On</span></label>
                              </div>-->
                            </div>
  
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="mActiveStatus"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Relay_Status_Column">
                          <th class="w35tbl" title="Sort By Relay Status" mat-header-cell *matHeaderCellDef
                            mat-sort-header> Relay
                            Status
                          </th>
                          <td mat-cell *matCellDef="let element">
                            <div class="led-box" *ngIf="element.mActiveStatus=='I'">
                              <!--<div class="led-red"></div>-->
                              <div>---</div>
                            </div>
                            <div class="" *ngIf="element.mActiveStatus=='N'">
                              <div class="toggle-switch-wrapper">
                                <label><span class="red">
                                    <i class="fa fa-power-off" aria-hidden="true"></i>
                                    Dis-Connect</span></label>
                              </div>
                            </div>
                            <div class="" *ngIf="element.mActiveStatus=='Y'">
                              <div class="toggle-switch-wrapper">
                                <label><span class="active">
                                    <i class="fa fa-plug" aria-hidden="true"></i>
                                    Connect</span></label>
                              </div>
                            </div>
                          </td>
                        </ng-container>
  
                        <ng-container matColumnDef="deadMeters"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_All_Live_Status_Column">
                          <th class="w35tbl" title="Sort By Live Status" mat-header-cell *matHeaderCellDef
                            mat-sort-header> Live Status
                          </th>
                          <td mat-cell *matCellDef="let element">
                            <div class="led-box" *ngIf="element.deadMeters=='I'">
                              <div>---</div>
                            </div>
                            <div class="" *ngIf="element.deadMeters=='C'">
                              <!--<div class="led-red"></div>
                              <div><i class="disconnectedic fa fa-times"><i><span></span></i></i></div>-->
                              <div class="toggle-switch-wrapper">
                                <label><span class="red">
                                    <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                                    Offline</span></label>
                              </div>
                            </div>
                            <div class="" *ngIf="element.deadMeters=='N'">
                              <div class="toggle-switch-wrapper">
                                <label><span class="active">
                                    <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                                    Online</span></label>
                              </div>
                              <!--<div class="led-green"></div>-->
                            </div>
                            <div class="" *ngIf="element.deadMeters=='Y'">
                              <!--<div><img style="width: 18px;" src="assets/images/comFail.png" /></div>
                              <div class="led-green"></div>-->
                              <div class="toggle-switch-wrapper">
                                <label><span class="inactive">
                                    <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                                    In-Active</span></label>
                              </div>
                            </div>
  
                          </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="fotaStatus"
                        *ngIf="getThrough.authority && getThrough.authority.Auth_All_Live_Status_Column">
                        <th class="w35tbl" title="Sort By Fota Status" mat-header-cell *matHeaderCellDef
                          mat-sort-header> Fota Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <div class="led-box" *ngIf="element.fotaStatus=='I'">
                            <div>---</div>
                          </div>
                          
                          <div class="" *ngIf="element.fotaStatus=='N'">
                            <div class="toggle-switch-wrapper">
                              <label><span class="red">
                                  <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                                  Pending</span></label>
                            </div>
                            <!--<div class="led-green"></div>-->
                          </div>
                          <div class="" *ngIf="element.fotaStatus=='Y'">
                            <!--<div><img style="width: 18px;" src="assets/images/comFail.png" /></div>
                            <div class="led-green"></div>-->
                            <div class="toggle-switch-wrapper">
                              <label><span class="active">
                                  <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                                  Done</span></label>
                            </div>
                          </div>

                        </td>
                      </ng-container>
  
  
                        <!--<ng-container matColumnDef="mActiveStatus">
                              <th mat-header-cell *matHeaderCellDef> Active Status </th>
                              <td mat-cell *matCellDef="let element" >
                                <fa name="circle" size="2x"
                                  class="{{element.mActiveStatus=='Y' ? 'dtatusOnColor' : 'dtatusOffColor'}}"></fa>
                              </td>
                            </ng-container>-->
  
                        <tr mat-header-row *matHeaderRowDef="meterColumnsToDisplay; sticky: true" class="tableHeader">
                        </tr>
                        <tr class="tableColumns" mat-row *matRowDef="let element; columns: meterColumnsToDisplay;">
                        </tr>
                      </table>
                    </div>
                    <!--<div>
                      <ejs-grid [dataSource]='data' [allowPaging]="true" [allowSorting]="true"
                        [allowFiltering]="true" [pageSettings]="pageSettings">
                        <e-columns>
                            <e-column field='OrderID' headerText='Order ID' textAlign='Right' width=90></e-column>
                            <e-column field='CustomerID' headerText='Customer ID' width=120></e-column>
                            <e-column field='Freight' headerText='Freight' textAlign='Right' format='C2' width=90></e-column>
                            <e-column field='OrderDate' headerText='Order Date' textAlign='Right' format='yMd' width=120></e-column>
                        </e-columns>
                        </ejs-grid>
                    </div>-->
                    <div *ngIf="loadingNoData">
                      <div>
                        <div>
                          <img class="center" src="assets/images/Nodata.png">
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <mat-card-footer>
          <div class="row">
            <div class="col-11" *ngIf="!loadingNoData">
              <mat-paginator #meterpaginator [length]="length" [pageSize]="allmeterlimit"
                [pageSizeOptions]="getPageSizeOptions()" (page)="changeAllMeterPage($event)" showFirstLastButtons>
              </mat-paginator>
            </div>
            <div class="col-1 text-left1">
              <div *ngIf="!loadingNoData">
                <button style="border:0;background: azure;" title="Export To Excel" (click)="exportTable()"><i
                    class="fa fa-file-excel-o" style="font-size:18px;color:rgb(0, 132, 255);"></i></button>
              </div>
            </div>
          </div>
        </mat-card-footer>
      </div>
    </div>
  </div>
  <!-- content viewport ends -->