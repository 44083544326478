import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../_services';
import { Access } from  '../../_models/access';
import { User } from '../../_models';
import { ConsumermodalService } from '../../_services/consumermodal.service';
import { take } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/_services/modal.service';
import { Subscription } from 'rxjs';
import { Notify } from 'src/app/_models/notify';

@Component({
  selector: 'app-leftnav',
  templateUrl: './leftnav.component.html',
  styleUrls: ['./leftnav.component.scss']
})
export class LeftnavComponent implements OnInit {
  getThrough: Access;
  subdivision: any;
  authBaseURl: any;
  currentUser: User;
  messageResult: any;
  meterDetail:any;
  enableSocietyChange=false;
  currentUserSubscription: Subscription;
  notifyCount: Notify;
  notifyCountSubscription: Subscription;
  menuCheck=false;
  constructor(
    private router: Router,
    private conmodalService: ConsumermodalService,
    private modalService: ModalService,
   
    private ngModalService: NgbModal,
    private authenticationService: AuthenticationService
  ) { 
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
    this.authBaseURl = this.authenticationService.getDeshboardRoute();
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

    if(this.currentUser.data.userData.authLevel=='7'){
      this.enableSocietyChange=true;
    }

    if(this.currentUser.data.userData.authLevel<'7'){
      this.menuCheck=true;
    }


    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.authenticationService.notify.subscribe(x => this.notifyCount = x);

  }

   ngOnInit() {
    this.subdivision=this.currentUser.data.userData.subdivision;
    //console.log("on left bar", this.getThrough.authority.Meters_Reports_Tariff_Config_Slab_Report);
    //this.getNotifyCount();
    if(this.currentUser){
      if( this.currentUser.data.userData.userType.toString().toLowerCase() == 'consumer'){
        this.consumerResetPassword(1);
      }
      if( this.currentUser.data.userData.userType.toString().toLowerCase() == 'authority'){
        this.authorityResetPassword(1);
      }
    }
  }

  ngOnDestroy(): void {
    this.ngModalService.dismissAll();
  }

  openConsumerEditProfile() {
    //editConsumerProfile
    this.conmodalService.consumerProfileEdit().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        this.messageResult = result;
      });
  }

  consumerComplaints() {
    //editConsumerProfile
    this.conmodalService.consumerComplaints().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
        this.messageResult = result;
      });
  }
  consumerNotifications() {
    //editConsumerProfile
    this.conmodalService.consumerNotifications().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
        this.messageResult = result;
      });
  }
  allComplaints() {
    //editConsumerProfile
    this.modalService.consumerAllComplaints().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
        this.messageResult = result;
      });
  }

  activityOnMeter(){
    this.conmodalService.activityOnMeterDialog().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  permConsumerAll(consumer) {
    this.modalService.permConsumer(consumer).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
     // console.log({ messageResult: result });
      this.messageResult = result;
    });
  }



  reportFromMeter(){
    this.conmodalService.reportingFromMeterDialog().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //  console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  allTeriff() {
    //editConsumerProfile
    this.conmodalService.consumerTeriff().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //  console.log({ messageResult: result });
        this.messageResult = result;
      });
  }

  allNotification() {
    //editConsumerProfile
    this.conmodalService.consumerNotificationSettings().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //  console.log({ messageResult: result });
        this.messageResult = result;
      });
  }

  loadLimiter() {
    //editConsumerProfile
    this.conmodalService.loadlimiterSettings().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
        this.messageResult = result;
      });
  }
  //12-4-2020



getProfileInfo() {
  if (this.authenticationService.isCurrentUserAuthority) {
    this.authorityProfileView();
  } else {
    this.consumerProfileView();
  }
}

getProfileEdit() {
  if (this.authenticationService.isCurrentUserAuthority) {
    this.authorityProfileEdit();
  } else {
    this.consumerProfileEdit();
  }
}

getNotifications() {
  if (this.authenticationService.isCurrentUserAuthority) {
    this.authorityNotifications();
  } else {
    this.consumerNotifications();
  }
}

authorityNotifications() {
  //editConsumerProfile
  this.conmodalService.authorityNotifications().pipe(
    take(1) // take() manages unsubscription for us
  ).subscribe(result => {
     //console.log({ messageResult: result });
      this.messageResult = result;
    });
}
resetPassword() {
  if (this.authenticationService.isCurrentUserAuthority) {
    this.authorityResetPassword(0);
  } else {
    this.consumerResetPassword(0);
  }
}

contactUs() {
  if (this.authenticationService.isCurrentUserAuthority) {
    this.authorityContactus();
  } else {
    this.consumerContactus();
  }
}

consumerContactus() {
    this.conmodalService.consumerContactus().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        this.messageResult = result;
      });
  }

  authorityContactus() {
    this.conmodalService.authorityContactus().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        this.messageResult = result;
      });
  }

consumerProfileView() {
  //editConsumerProfile
  this.conmodalService.consumerProfileView().pipe(
    take(1) // take() manages unsubscription for us
  ).subscribe(result => {
      //console.log({ messageResult: result });
      this.messageResult = result;
    });
}

consumerProfileEdit() {
  //editConsumerProfile
  this.conmodalService.consumerProfileEdit().pipe(
    take(1) // take() manages unsubscription for us
  ).subscribe(result => {
      //console.log({ messageResult: result });
      this.messageResult = result;
    });
}

authorityProfileEdit() {
  //editConsumerProfile
  this.conmodalService.authorityProfileEdit().pipe(
    take(1) // take() manages unsubscription for us
  ).subscribe(result => {
      //console.log({ messageResult: result });
      this.messageResult = result;
    });
}



authorityProfileView() {
  //editConsumerProfile
  this.conmodalService.authorityProfileView().pipe(
    take(1) // take() manages unsubscription for us
  ).subscribe(result => {
      //console.log({ messageResult: result });
      this.messageResult = result;
    });
}


  consumerResetPassword(set) {
    this.conmodalService.consumerResetPassword(set).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        this.messageResult = result;
      });
  }

  authorityResetPassword(set) {
    this.conmodalService.authorityResetPassword(set).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        this.messageResult = result;
      });
  }




}
