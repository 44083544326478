

import { Component, OnInit, Input, ViewChild, ElementRef, HostBinding, HostListener, SimpleChanges } from '@angular/core';

import * as L from 'leaflet';
//import * as screenfull from 'screenfull';
//import 'leaflet.markercluster';


@Component({
	selector: 'map',
	templateUrl: './map.component.html'
	/*host: {
		'(document:keypress)': 'handleKeyboardEvent($event)'
	}*/
})
export class MapComponent implements OnInit {
	screenHeight = "height:88vh;";

	styleButton1 = "padding: 0px;background: azure; margin-top: 62px;	margin-left: 7px;padding: 3px;border-color: orchid;";
	styleButton2 = "padding: 0px;background: azure; margin-top: 62px;	margin-left: 0px;padding: 3px;border-color: orchid;";
	displaybtnn = "border: 2px solid #615f5f;display: flex;justify-content: center;align-items: center;margin-top: 8px;margin-left: 6px;display: flex;width: 30px;height: 25px;border-radius:2px;font-size:13px;";
	displaybtnright = " position: absolute;z-index: 2;right: 20px;top: 4px;";
	toggleClass = 'ft-maximize';
	options: any
	iconValue = 0;
	contant = '';
	contantX = '';
	contantY = '';
	contantZ = '';
	contant1 = '';
	contant2 = '';
	contant3 = '';
	contant4 = '';
	contant5 = '';
	contant6 = '';
	contant7 = '';
	contant8 = '';
	contant9 = '';
	contant10 = '';
	display = true;
	keypressed;
	coordsNew: any;
	@Input() coords: number[][] = [];

	// Marker cluster stuff


	markerClusterGroup: L.MarkerClusterGroup;
	markerClusterData: L.Marker[] = [];
	markerClusterOptions: L.MarkerClusterGroupOptions;
	@ViewChild('fs') fs: ElementRef;

	@HostBinding('class.is-fullscreen') isFullscreen = false;

	isActive = false;

	ngOnInit() {
		this.markerClusterData = this.generateMarker(this.coords);
		//console.log("on map",this.markerClusterData);
		this.options = {
			zoom: 4,
			maxZoom: 18,
			center: L.latLng([this.coords[0][0], this.coords[0][1]]),
			layers: [L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {})],
			attributionControl: false
		};

	}

	markerClusterReady(group: L.MarkerClusterGroup) {
		this.markerClusterGroup = group;
	}

	generateMarker(coords: number[][]): L.Marker[] {

		let coordsNew = coords[1];
		//console.log("on map",coords[1],"on map",coordsNew);
		const data: L.Marker[] = [];
		const icon1 = L.icon({
			className: 'icon-position',
			iconSize: [25, 41],
			iconAnchor: [10, 41],
			popupAnchor: [2, -40],
			// specify the path here
			iconUrl: "assets/images/Dis.png",
			shadowUrl:
				"https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png"
		});

		const icon2 = L.icon({
			className: 'icon-position',
			iconSize: [25, 41],
			iconAnchor: [10, 41],
			popupAnchor: [2, -40],
			// specify the path here
			iconUrl: "assets/images/Offline.png",
			shadowUrl:
				"https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png"
		});

		const icon3 = L.icon({
			className: 'icon-position',
			iconSize: [25, 41],
			iconAnchor: [10, 41],
			popupAnchor: [2, -40],
			// specify the path here
			iconUrl: "assets/images/Online.png",
			shadowUrl:
				"https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png"
		});
		if (coordsNew) {
			for (let i = 0; i < coordsNew.length; i++) {
				if (coordsNew[i][16] == '105') {
					
						const icon1 = L.icon({
							className: 'icon-position',
							iconSize: [25, 41],
							iconAnchor: [10, 41],
							popupAnchor: [2, -40],
							// specify the path here
							iconUrl: "assets/images/Dis.png",
							shadowUrl:
								"https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png"
						});
	
						//this.contant='<div><table class="blueTable"><thead><tr><th colspan="4">Meter Information</th></tr></thead><tr><th>Society Name:</th><td>{{coords[i][0]}}</td><th>Consumer Name:</th><td>{{coords[i][0]}}</td></tr><tr><th>Flat No:</th><td>{{coords[i][0]}}</td><th>Meter Id:</th><td>{{coords[i][0]}}</td></tr><tr><th>Last Update:</th><td>{{coords[i][0]}}</td><th>Last Recharge:</th><td>{{coords[i][0]}}</td></tr></table></div>';
						this.contantX = '<div><table class="blueTable"><thead><tr><th style="background: gray;" colspan="4">Meter Information</th></tr></thead>';
						this.contantY = '<div><table class="blueTable"><thead><tr><th style="background: crimson;" colspan="4">Meter Information</th></tr></thead>';
						this.contantZ = '<div><table class="blueTable"><thead><tr><th style="background: mediumseagreen;" colspan="4">Meter Information</th></tr></thead>';
	
						if (coordsNew[i][7] != null) {
							this.contant1 = '<tr><th>State:</th><td>' + coordsNew[i][7] + '</td></tr>';
						}
						if (coordsNew[i][15] != null) {
							this.contant2 = '<tr><th>Circle:</th><td>' + coordsNew[i][15] + '</td></tr>';
						}
						if (coordsNew[i][0] != null) {
							this.contant3 = '<tr><th>Consumer Name:</th><td>' + coordsNew[i][0] + '</td></tr>';
						}
						if (coordsNew[i][17] != null) {
							this.contant10 = '<tr><th>Mobile No:</th><td>'+coordsNew[i][17]+'</td></tr>';
						}
						if (coordsNew[i][1] != null) {
							this.contant4 = '<tr><th>Account No:</th><td>' + coordsNew[i][1] + '</td></tr>';
						}
						if (coordsNew[i][4] != null) {
							this.contant5 = '<tr><th>Meter Id:</th><td>' + coordsNew[i][4] + '</td></tr>';
						}
						
						/*if (coordsNew[i][10] != null && coordsNew[i][11]) {
							this.contant9 = '<tr><th>Location:</th><td><a href="https://maps.google.com/?q='+coordsNew[i][10]+','+ coordsNew[i][11]+'" target="_blank">'+ coordsNew[i][10] +','+ coordsNew[i][11]+'</a></td></tr>';
						}*/
						if (coordsNew[i][10] != null && coordsNew[i][11]) {
							this.contant9 = '<tr><th>Location:</th><td><a href="https://www.google.com/maps/dir/?api=1&destination=' + coordsNew[i][10] + ',' + coordsNew[i][11] + '&travelmode=driving" target="_blank">' + coordsNew[i][10] + ',' + coordsNew[i][11] + '</a></td></tr>';
						}
						this.contant8 = '</table></div>';
						this.iconValue = coordsNew[i][13];
						if (this.iconValue == 0) {
							this.contant = this.contantX + this.contant2 + this.contant3 + this.contant10 + this.contant4 + this.contant5 + this.contant6 + this.contant7 + this.contant9 + this.contant8;
							data.push(L.marker([coordsNew[i][10], coordsNew[i][11]], { icon: icon1 }).bindTooltip(coordsNew[i][9],
								{
									permanent: false,
									direction: 'auto',
									sticky: false,
									offset: [5, 0],
									opacity: 0.95,
	
								}).bindPopup(this.contant));
						} if (this.iconValue == 1) {
							this.contant = this.contantY + this.contant2 + this.contant3 + this.contant10 + this.contant4 + this.contant5 + this.contant6 + this.contant7 + this.contant9 + this.contant8;
							data.push(L.marker([coordsNew[i][10], coordsNew[i][11]], { icon: icon2 }).bindTooltip(coordsNew[i][9],
								{
									permanent: false,
									direction: 'auto',
									sticky: false,
									offset: [5, 0],
									opacity: 0.95,
	
								}).bindPopup(this.contant));
						} if (this.iconValue == 2) {
							this.contant = this.contantZ + this.contant2 + this.contant3 + this.contant10 + this.contant4 + this.contant5 + this.contant6 + this.contant7 + this.contant9 + this.contant8;
							data.push(L.marker([coordsNew[i][10], coordsNew[i][11]], { icon: icon3 }).bindTooltip(coordsNew[i][9],
								{
									permanent: false,
									direction: 'top',
									sticky: false,
									offset: [20, 0],
									opacity: 0.95,
	
								}).bindPopup(this.contant));
						}
					
				}else{
					const icon1 = L.icon({
						className: 'icon-position',
						iconSize: [25, 41],
						iconAnchor: [10, 41],
						popupAnchor: [2, -40],
						// specify the path here
						iconUrl: "assets/images/Dis.png",
						shadowUrl:
							"https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png"
					});

					//this.contant='<div><table class="blueTable"><thead><tr><th colspan="4">Meter Information</th></tr></thead><tr><th>Society Name:</th><td>{{coords[i][0]}}</td><th>Consumer Name:</th><td>{{coords[i][0]}}</td></tr><tr><th>Flat No:</th><td>{{coords[i][0]}}</td><th>Meter Id:</th><td>{{coords[i][0]}}</td></tr><tr><th>Last Update:</th><td>{{coords[i][0]}}</td><th>Last Recharge:</th><td>{{coords[i][0]}}</td></tr></table></div>';
					this.contantX = '<div><table class="blueTable"><thead><tr><th style="background: gray;" colspan="4">Meter Information</th></tr></thead>';
					this.contantY = '<div><table class="blueTable"><thead><tr><th style="background: crimson;" colspan="4">Meter Information</th></tr></thead>';
					this.contantZ = '<div><table class="blueTable"><thead><tr><th style="background: mediumseagreen;" colspan="4">Meter Information</th></tr></thead>';

					if (coordsNew[i][7] != null) {
						this.contant2 = '<tr><th>Society Name:</th><td>' + coordsNew[i][7] + '</td></tr>';
					}
					if (coordsNew[i][0] != null) {
						this.contant3 = '<tr><th>Consumer Name:</th><td>' + coordsNew[i][0] + '</td></tr>';
					}
					if (coordsNew[i][9] != null) {
						this.contant4 = '<tr><th>Flat No:</th><td>' + coordsNew[i][9] + '</td></tr>';
					}
					if (coordsNew[i][4] != null) {
						this.contant5 = '<tr><th>Meter Id:</th><td>' + coordsNew[i][4] + '</td></tr>';
					}
					if (coordsNew[i][5] != null) {
						this.contant6 = '<tr><th>Last Update:</th><td>' + coordsNew[i][5] + '</td></tr>';
					}
					if (coordsNew[i][6] != null) {
						this.contant7 = '<tr><th>Last Recharge:</th><td>' + coordsNew[i][6] + '</td></tr>';
					}
					/*if (coordsNew[i][10] != null && coordsNew[i][11]) {
						this.contant9 = '<tr><th>Location:</th><td><a href="https://maps.google.com/?q='+coordsNew[i][10]+','+ coordsNew[i][11]+'" target="_blank">'+ coordsNew[i][10] +','+ coordsNew[i][11]+'</a></td></tr>';
					}*/
					if (coordsNew[i][10] != null && coordsNew[i][11]) {
						this.contant9 = '<tr><th>Location:</th><td><a href="https://www.google.com/maps/dir/?api=1&destination=' + coordsNew[i][10] + ',' + coordsNew[i][11] + '&travelmode=driving" target="_blank">' + coordsNew[i][10] + ',' + coordsNew[i][11] + '</a></td></tr>';
					}
					this.contant8 = '</table></div>';
					this.iconValue = coordsNew[i][13];
					if (this.iconValue == 0) {
						this.contant = this.contantX + this.contant2 + this.contant3 + this.contant4 + this.contant5 + this.contant6 + this.contant7 + this.contant9 + this.contant8;
						data.push(L.marker([coordsNew[i][10], coordsNew[i][11]], { icon: icon1 }).bindTooltip(coordsNew[i][9],
							{
								permanent: false,
								direction: 'auto',
								sticky: false,
								offset: [5, 0],
								opacity: 0.95,

							}).bindPopup(this.contant));
					} if (this.iconValue == 1) {
						this.contant = this.contantY + this.contant2 + this.contant3 + this.contant4 + this.contant5 + this.contant6 + this.contant7 + this.contant9 + this.contant8;
						data.push(L.marker([coordsNew[i][10], coordsNew[i][11]], { icon: icon2 }).bindTooltip(coordsNew[i][9],
							{
								permanent: false,
								direction: 'auto',
								sticky: false,
								offset: [5, 0],
								opacity: 0.95,

							}).bindPopup(this.contant));
					} if (this.iconValue == 2) {
						this.contant = this.contantZ + this.contant2 + this.contant3 + this.contant4 + this.contant5 + this.contant6 + this.contant7 + this.contant9 + this.contant8;
						data.push(L.marker([coordsNew[i][10], coordsNew[i][11]], { icon: icon3 }).bindTooltip(coordsNew[i][9],
							{
								permanent: false,
								direction: 'top',
								sticky: false,
								offset: [20, 0],
								opacity: 0.95,

							}).bindPopup(this.contant));
					}
				}
				//console.log("on html",data);

			}
		}
		return data;
	}

	@HostListener('document:fullscreenchange', ['$event'])
	@HostListener('document:webkitfullscreenchange', ['$event'])
	@HostListener('document:mozfullscreenchange', ['$event'])
	@HostListener('document:MSFullscreenChange', ['$event'])
	fullscreenmode() {
		if (this.toggleClass == 'ft-minimize') {
			this.toggleClass = 'ft-maximize';
			this.display = true;
			this.screenHeight = "height:360px;";
			this.isFullscreen = false;
			this.isActive = false;
		}
		else {
			this.display = false;
			this.screenHeight = "height:100vh";
			this.isFullscreen = true;
			this.toggleClass = 'ft-minimize';
		}
	}
	openFullscreen(): void {
		this.display = false;
		this.screenHeight = "height:100vh";
		this.isFullscreen = true;
		const el = this.fs.nativeElement;

		if (!document.fullscreenElement) {  // current working methods
			if (el.requestFullscreen) {
				el.requestFullscreen();
			} else if (el.mozRequestFullScreen) {
				el.mozRequestFullScreen();
			} else if (el.msRequestFullscreen) {
				el.msRequestFullscreen();
			}
		}

	}
	closeFullscreen(): void {
		this.display = true;
		this.screenHeight = "height:360px;";
		this.isFullscreen = false;
		this.isActive = false;

		if (document['exitFullscreen']) {
			document['exitFullscreen']();
		} else if (document['msExitFullscreen']) {
			document['msExitFullscreen']();
		} else if (document['mozCancelFullScreen']) {
			document['mozCancelFullScreen']();
		} else if (document['webkitExitFullscreen']) {
			document['webkitExitFullscreen']();
		}
	}
}
